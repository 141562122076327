import { EmptyFolder, Loading } from 'components';
import { FC, PropsWithChildren, ReactNode } from 'react';

import ErrorFallback from './components/ErrorFallback';

const BlockUI: FC<
  PropsWithChildren & {
    loading?: boolean;
    error?: boolean;
    fallbackCondition?: boolean;
    fallback?: ReactNode;
  }
> = ({
  children,
  loading,
  error = false,
  fallbackCondition,
  fallback = <EmptyFolder title="No data." />,
}) => {
  if (error) return <ErrorFallback />;
  else if (loading) return <Loading />;
  else return fallbackCondition ? fallback : children;
};

export default BlockUI;
