import { GlowScroll, Image } from '@faxi/web-component-library';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { FC, Fragment, ReactNode, useCallback } from 'react';

import DataGridPreview from '../../../../components/_molecules/DataGridPreview';
import Icon from '../../../../components/Icon';
import { Activity } from '../../../../models';
import StatusFlag from '../../../../routes/components/NotificationsGrid/components/StatusFlag';
import SessionHeader from '../SessionHeader';
import { sessionActivities } from './data';
import { StyledSessionActivity } from './SessionActivity.styled';

export type SessionActivityProps = {};
dayjs.extend(isToday);

const SessionActivity: FC<SessionActivityProps> = () => {
  const renderActivities = useCallback(
    ({
      isRead,
      user: { img: userImg, firstName, lastName },
      campaignName,
      organisation: { img: organisationImg, name: organisationName },
      topic,
      action,
    }: Activity): ReactNode[] => [
      <Fragment>
        <StatusFlag initialIsRead={isRead} />
        <div className="user-image">
          <div className="user-image-container">
            {userImg ? (
              <Image src={userImg} alt={`${firstName} ${lastName}`} />
            ) : (
              <Icon name="user-solid" />
            )}
          </div>
        </div>
        {firstName} {lastName}
      </Fragment>,
      <div>{campaignName}</div>,
      <div>
        <Image
          src={organisationImg || '/assets/images/kinto-join.png'}
          alt="organisation"
        />
        {organisationName}
      </div>,
      <div>{topic}</div>,
      <div>{action}</div>,
    ],
    []
  );

  return (
    <StyledSessionActivity className="esg-session-activity">
      <SessionHeader heading="Activity" />
      <div className="esg-session-activity__content">
        {sessionActivities?.map(({ activities, date }, index) => (
          <div className="esg-profile-notifications__on-date" key={index}>
            <div className="esg-profile-notifications__on-date__date">
              {dayjs(date).isToday()
                ? 'Today'
                : dayjs(date).format('DD MMMM YYYY')}
            </div>
            <GlowScroll variant="gray">
              <DataGridPreview
                data={activities}
                renderColumns={renderActivities}
              />
            </GlowScroll>
          </div>
        ))}
      </div>
    </StyledSessionActivity>
  );
};

export default SessionActivity;
