import { CampaignItem } from 'models';
import useSWR from 'swr';

import { API_ROUTES } from '../routes';

const useGetCampaignItem = (id?: string) => {
  const { data, error, ...rest } = useSWR<{ data: CampaignItem }>(
    id && API_ROUTES.CAMPAIGN_ITEMS_ROUTES.CAMPAIGN_ITEM(id)
  );

  return {
    campaignItem: data?.data,
    isError: error,
    ...rest,
  };
};

export default useGetCampaignItem;
