import env from 'env';

const apiVersion = env.VITE_API_VERSION;

const baseUrl = `/campaign-items/${apiVersion}`;

export const CAMPAIGN_ITEMS_ROUTES = {
  BASE: baseUrl,
  CAMPAIGN_ITEM: (id: string) => `${baseUrl}/${id}`,
  CAMPAIGN_DATA_COLLECTION_ELEMENTS: (id: string) =>
    `${baseUrl}/${id}/data-collection-elements`,
  CAMPAIGN_TREE: (id: string) => `${baseUrl}/${id}/tree`,
};
