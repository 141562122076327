import { Divider, theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledUserProfileActions = styled.div`
  ${flex('row')};

  gap: ${theme.sizes.SIZE_40};
  margin-top: ${theme.sizes.SIZE_40};
  max-width: ${theme.sizes.SIZE_504};
`;

const StyledDividerProfileActions = styled(Divider)`
  margin-top: ${theme.sizes.SIZE_32};
  max-width: ${theme.sizes.SIZE_504};
`;

export { StyledDividerProfileActions, StyledUserProfileActions };
