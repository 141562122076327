import { IDataModule } from 'models';
import useSWR from 'swr';

import { API_ROUTES } from '../routes';

const useGetCampaignDataCollectionElements = (id: string) => {
  const { data, ...rest } = useSWR<{ data?: IDataModule[] }>(
    API_ROUTES.CAMPAIGN_ITEMS_ROUTES.CAMPAIGN_DATA_COLLECTION_ELEMENTS(id)
  );

  return {
    dataCollectionElements: data?.data,
    ...rest,
  };
};

export default useGetCampaignDataCollectionElements;
