import { LabelValuePair, ObjectValues } from 'types';

import { INameExtended } from '../components/Icon';
import { BaseModel } from './BaseModel';
import { Organisation } from './Organisation';
import { PermissionSections } from './Permissions';

export const USER_ROLE = {
  REGULAR_USER: 'basic user',
  ORGANISATION_ADMIN: 'org admin',
  SUPER_ADMIN: 'super admin',
  AUDIT: 'auditor',
} as const;

export type UserRoleName = ObjectValues<typeof USER_ROLE>;

export type UserRole = {
  color: string;
  name: UserRoleName;
  id: string;
  description?: string;
};

export enum Permission {
  CREATE_ORGANISATION,
  CREATE_COMPANY,
  CREATE_REPORT,
  CREATE_TOPIC,
  CREATE_CATEGORY,
  CREATE_TASK,
  SUPERVISE_ACTIVITY,
  HANDLE_REPORT, // APPROVE OR REJECT,
  CHECK_REPORT_STATUS,
  COLLECT_DATA,
  ENTER_DATA,
}

export type User = BaseModel & {
  firstName: string;
  lastName: string;
  img?: string;
  email: string;
  roles: UserRole[];
  jobTitle?: string;
  organisation: Pick<Organisation, 'id' | 'name'>;
};

export type Role = {
  id: string;
  name: UserRoleName;
  color: string;
  description?: string;
  permissions?: string[];
};

export type UserForm = {
  firstName: string;
  lastName: string;
  email: string;
  roleId: string;
  jobTitle?: string;
};

type BaseActions = 'read' | 'create' | 'edit' | 'delete';
type CampaignsActions = BaseActions;
type RoleActions = BaseActions;
export type OrganisationActions = BaseActions;
type SessionActions = BaseActions;
type UserActions = BaseActions | 'assign_roles' | 'assign_users';

type ActionMapping = {
  [PermissionSections.CAMPAIGN]: CampaignsActions;
  [PermissionSections.ORGANISATION]: OrganisationActions;
  [PermissionSections.USER]: UserActions;
  [PermissionSections.SESSION]: SessionActions;
  [PermissionSections.ROLE]: RoleActions;
};

export type ActionType<T extends PermissionSections> =
  T extends keyof ActionMapping ? ActionMapping[T] : BaseActions;

export type ActionProps = {
  label: string;
  name: string;
  excludes?: string[];
  includes?: string[];
};

export type UserRoleFormSectionAction<T extends PermissionSections> = Record<
  ActionType<T>,
  ActionProps
>;

export type UserRoleFormSection<T extends PermissionSections> = {
  icon: INameExtended;
  label: T;
  actions?: UserRoleFormSectionAction<T>;
};
export type UserRoleForm = {
  [P in PermissionSections]: UserRoleFormSection<P>;
};

export type UserDetails = {
  userInformation: LabelValuePair<string>[];
  roleInformation: LabelValuePair<UserRole>;
};
