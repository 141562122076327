import { useUtilities } from '@faxi/web-component-library';
import { FC, Fragment } from 'react';

import { useRootProvider } from '../../../../../../../providers/Root';
import ProfileDetails from '../../../../../../components/ProfileDetails';
import UserProfileActions from '../../../../components/UserProfileActions';

const UserDetails: FC = () => {
  const { user } = useRootProvider();

  const { showSnackBar } = useUtilities();

  //TODO: connect with api when finished

  return (
    <Fragment>
      <ProfileDetails user={user} />
      <UserProfileActions
        user={user}
        isLoading={false}
        onEdit={() => {
          showSnackBar({
            text: 'Backend implementation missing',
            variant: 'error',
          });
        }}
        onChangePassword={(data) => {
          showSnackBar({
            text: 'Backend implementation missing',
            variant: 'error',
          });
          return Promise.resolve();
        }}
        isEditingOwnProfile
      />
    </Fragment>
  );
};

export default UserDetails;
