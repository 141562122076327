import styled from 'styled-components';

const StyledCheckboxTreeView = styled.div`
  width: 100%;

  .esg-checkbox-tree-view {
  }
`;

export { StyledCheckboxTreeView };
