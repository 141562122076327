import { Modal, theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledEntityFormModal = styled(Modal)`
  .wcl-modal {
    .textarea__container {
      width: 100%;

      textarea {
        min-width: 100%;
        max-width: max-content;
        min-height: ${theme.sizes.SIZE_104};
      }

      &__placeholder {
        transition-property: font-size, padding, top;
        transition-duration: 150ms;
        transition-timing-function: ease-in;
        transition-delay: 0s;
      }
    }

    &__main {
      gap: ${theme.sizes.SIZE_20};
    }
    .campaign-description {
      ${flex('row', 'flex-start', 'flex-start')};
      gap: ${theme.sizes.SIZE_12};
      .wcl-icon {
        margin-top: ${theme.sizes.SIZE_4};
      }
      div {
        color: var(--GRAY-70);
      }
    }
  }
`;

export { StyledEntityFormModal };
