import classNames from 'classnames';
import { TreeNodeElement } from 'models';
import { FC } from 'react';

import { StyledBasicTreeView } from './BasicTreeView.styled';
import { TreeNode, TreeNodeProps } from './components';

export type BasicTreeViewProps = {
  data?: (TreeNodeElement & { canDelete?: boolean }) | null;
  className?: string;
  activeNodeId?: string;
  onCheck?: (
    node: TreeNodeElement,
    checked: TreeNodeElement['checked']
  ) => void;
} & Pick<
  TreeNodeProps,
  'onDelete' | 'onDuplicate' | 'withMenu' | 'showRootNode'
>;

/**
 * A basic tree view component that displays a hierarchical tree structure.
 */
const BasicTreeView: FC<BasicTreeViewProps> = ({
  data,
  className,
  activeNodeId,
  ...rest
}) => (
  <StyledBasicTreeView className={classNames('esg-basic-tree-view', className)}>
    <TreeNode level={1} activeNodeId={activeNodeId} node={data!} {...rest} />
  </StyledBasicTreeView>
);

export default BasicTreeView;
