import { useUtilities } from '@faxi/web-component-library';
import api from 'api';
import Icon from 'components/Icon';
import { Organisation } from 'models';
import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  NavigateFunction,
  Outlet,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { findNode } from 'utils';

import { API_ROUTES } from '../../api/routes';
import { getPathSegment } from '../../routes/subrouters/Organisations/utils';
import useOrganisationTree from '../hooks/useOrganisationTree';
import OrganisationContext, {
  OrganisationContextProps,
} from './Organisation.context';

type OrganisationProviderProps = PropsWithChildren;

const redirectAfterDeletion = (
  navigate: NavigateFunction,
  deleteId: string,
  activeOrganisation?: Organisation
) => {
  const parentIds = activeOrganisation?.path.ids ?? [];
  const isSubCompanyDeleted = parentIds.includes(deleteId);

  if (isSubCompanyDeleted) {
    const indexOfElement = parentIds.indexOf(deleteId) - 1;

    const baseOrganisationPath = getPathSegment(window.location.pathname);

    let newPath = baseOrganisationPath;

    // if we are deleting direct child from organisation we need to redirect user to the organisation
    if (indexOfElement > 0) {
      newPath += `/companies/${parentIds[indexOfElement]}`;
    }

    navigate(newPath);
  }
};

const OrganisationProvider: FC<OrganisationProviderProps> = () => {
  const navigate = useNavigate();
  const { prompts, showOverlay, hideOverlay } = useUtilities();

  const params = useParams<{
    organisationId?: string;
  }>();

  const [deletingOrgId, setDeletingOrgId] = useState<string>();
  const { trigger: deleteOrg } = api.useMutation(
    API_ROUTES.COMPANIES.ORGANISATION(deletingOrgId as string)
  );

  const { organisationTree, rootOrganisation, error } =
    useOrganisationTree('organisations');

  const currentOrganisationId = params.organisationId || rootOrganisation?.id;

  const activeOrganisation = useMemo(
    () =>
      rootOrganisation && currentOrganisationId
        ? findNode<Organisation>(currentOrganisationId, [rootOrganisation])
        : undefined,
    [currentOrganisationId, rootOrganisation]
  );

  const deleteOrganisation = useCallback(
    async (
      org: Organisation,
      trigger?: HTMLButtonElement,
      callback?: () => void
    ) => {
      setDeletingOrgId(org.id);

      const proceed = await prompts.delete({
        type: 'delete',
        title: `Remove ${org?.name}`,
        content: `Are you sure you want to remove company ${org?.name}?`,
        submitBtnText: 'Remove',
        cancelBtnText: 'Do not remove',
        btnIcon: 'trash-can',
        iconPosition: 'left',
        submitBtnVariant: 'delete-ghost',
        triggerRef: trigger as HTMLButtonElement,

        titleIcon: <Icon name="triangle-exclamation" />,
      });

      if (!proceed) return;
      showOverlay('body');

      try {
        await deleteOrg({ method: 'DELETE' });
        // TODO
        // await mutateOrganisations();
        callback?.();
        redirectAfterDeletion(navigate, org.id, activeOrganisation);

        setDeletingOrgId(undefined);
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('body');
      }
    },
    [navigate, activeOrganisation, prompts, showOverlay, deleteOrg, hideOverlay]
  );

  return (
    <OrganisationContext.Provider
      value={{
        loading: false,
        error: !!error,
        activeOrganisation,
        organisationTree,
        deleteOrganisation,
      }}
    >
      <Outlet />
    </OrganisationContext.Provider>
  );
};

const useOrganisationProvider = (): OrganisationContextProps =>
  useContext(OrganisationContext);

export { OrganisationProvider, useOrganisationProvider };
