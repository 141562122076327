import { AxiosError, AxiosRequestConfig } from 'axios';
import useSWRMutation, { SWRMutationConfiguration } from 'swr/mutation';

import axiosInstance from '../../axiosInstance';

const useMutation = <TData = any, TError = AxiosError<{ error: string }>>(
  key: string,
  config?: SWRMutationConfiguration<TData, TError, string, AxiosRequestConfig>
) =>
  useSWRMutation<TData, TError, string, AxiosRequestConfig>(
    key,
    (_, { arg: { url = key, ...rest } }) =>
      axiosInstance.request({ url, ...rest }).then((data) => data.data),
    config
  );

export default useMutation;
