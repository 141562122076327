import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledErrorFallback = styled.div`
  ${flex('column', 'center', 'center')};
  width: 100%;
  height: 100%;
  .wcl-icon {
    svg {
      ${fontSize(theme.fontSizes.FONT_64)};
    }
  }

  p {
    margin-bottom: ${theme.sizes.SIZE_32};
  }
`;

export { StyledErrorFallback };
