import { CAMPAIGN_ITEMS_ROUTES } from './CampaignItemsRoutes';
import { CAMPAIGN_SESSIONS_ROUTES } from './CampaignSessionsRoutes';
import { COMPANIES } from './Companies';
import { SESSIONS } from './Sessions';
import { USERS } from './Users';

export const API_ROUTES = {
  COMPANIES,
  CAMPAIGN_ITEMS_ROUTES,
  CAMPAIGN_SESSIONS_ROUTES,
  USERS,
  SESSIONS,
};
