import { DataModuleEnum, ModuleConfigMapperType } from 'models';

import BaseFormulaFieldConfiguration from '../baseModules/BaseFormulaFieldConfiguration';
import BaseInputFieldConfiguration from '../baseModules/BaseInputFieldConfiguration';
import BaseIntegrationFieldConfiguration from '../baseModules/BaseIntegrationFieldConfiguration';
import BaseTextFieldConfiguration from '../baseModules/BaseTextFieldConfiguration';
import ConditionalCheckListFieldConfiguration from './ConditionalCheckListFieldConfiguration';
import ConditionalChoiceFieldConfiguration from './ConditionalChoiceFieldConfiguration';
import ConditionalDropdownFieldConfiguration from './ConditionalDropdownFieldConfiguration';
import ConditionalSwitchFieldConfiguration from './ConditionalSwitchFieldConfiguration';
import ConditionalUploadFieldConfiguration from './ConditionalUploadFieldConfiguration';

export const CONDITIONAL_CONFIGURABLE_MODULES: ModuleConfigMapperType = {
  [DataModuleEnum.INPUT]: BaseInputFieldConfiguration,
  [DataModuleEnum.CHOICE]: ConditionalChoiceFieldConfiguration,
  [DataModuleEnum.CHECKLIST]: ConditionalCheckListFieldConfiguration,
  [DataModuleEnum.DROPDOWN]: ConditionalDropdownFieldConfiguration,
  [DataModuleEnum.SWITCH]: ConditionalSwitchFieldConfiguration,
  [DataModuleEnum.INTEGRATION]: BaseIntegrationFieldConfiguration,
  [DataModuleEnum.UPLOAD]: ConditionalUploadFieldConfiguration,
  [DataModuleEnum.TEXT_BOX]: BaseTextFieldConfiguration,
  [DataModuleEnum.FORMULA]: BaseFormulaFieldConfiguration,
};
