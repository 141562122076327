import classNames from 'classnames';
import Icon from 'components/Icon';
import { FC, memo, PropsWithChildren } from 'react';

import { StyledNoData } from './NoData.styled';

export type ErrorFallbackProps = PropsWithChildren<{
  title?: string;
  className?: string;
}>;

const NoData: FC<ErrorFallbackProps> = ({ title = 'No data.', className }) => (
  <StyledNoData className={classNames('esg-error-fallback', className)}>
    <Icon name="folder-open" />
    <p>{title}</p>
  </StyledNoData>
);

export default memo(NoData);
