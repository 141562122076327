import { User } from '../../../../models';

export const members: User[] = [
  {
    'id': '13a44802-5051-70cf-3b97-2810ea384590',
    'email': 'dusan.simijonovic@faxi.co.uk',
    'firstName': 'Dusan',
    'lastName': 'Simijonovic',
    'img': '/assets/images/avatars/dule.jpeg',
    'jobTitle': 'Upd',
    'roles': [
      {
        'id': '3cf9f61e-880a-47b7-8890-2cbe8dc6d5d0',
        'name': 'super admin',
        'color': '#EE7D01',
      },
    ],
    'organisation': {
      'id': '',
      'name': '',
    },
  },
  {
    'id': '43748852-1021-70df-0e75-78b4309aaa51',
    'email': 'aleksandar.stojkovic@kintojoin.io',
    'firstName': 'Aleksandar',
    'lastName': 'Stojkovic',
    'jobTitle': 'QA',
    'roles': [
      {
        'id': '3cf9f61e-880a-47b7-8890-2cbe8dc6d5d0',
        'name': 'super admin',
        'color': '#EE7D01',
      },
    ],
    'organisation': {
      'id': '',
      'name': '',
    },
  },
  {
    'id': '13f4d8f2-c0d1-70f3-f1ed-0fd5d4e9a713',
    'email': 'stefan.stojanovic@kintojoin.io',
    'firstName': 'Stefan',
    'lastName': 'Stojanovic',
    'jobTitle': 'profile update',
    'roles': [
      {
        'id': '3cf9f61e-880a-47b7-8890-2cbe8dc6d5d0',
        'name': 'super admin',
        'color': '#EE7D01',
      },
    ],
    'organisation': {
      'id': '',
      'name': '',
    },
  },
  {
    'id': '13f4d8f2-c0d1-70f3-f1ed-0fd5d4e9a714',
    'email': 'aleksandra.aleksandrovic@kintojoin.io',
    'firstName': 'Aleksandra',
    'lastName': 'Aleksandrovic Petrovic',
    'jobTitle': 'profile update',
    'roles': [
      {
        'id': '3cf9f61e-880a-47b7-8890-2cbe8dc6d5d0',
        'name': 'super admin',
        'color': '#EE7D01',
      },
    ],
    'organisation': {
      'id': '',
      'name': '',
    },
  },
];
