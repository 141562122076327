import { SelectOption, useUtilities } from '@faxi/web-component-library';
import { RunSessionData, Session } from 'models';
import {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useMutation from '../../api/hooks/mutations/useMutation';
import useGetSessions from '../../api/hooks/sessions/useGetSessions';
import { API_ROUTES } from '../../api/routes';
import { Loading } from '../../components';
import { APP_URI } from '../../config';
import { useDebouncedValue } from '../../hooks';
import useOrganisationTree from '../hooks/useOrganisationTree';
import SessionsDataContext, { SessionContextProps } from './Session.context';

type SessionProviderProps = {
  children?: ReactNode;
};

const SessionProvider: FC<SessionProviderProps> = (props) => {
  const { children } = props;

  const [search, setSearch] = useState<string>('');
  const [selectedOrganisation, setSelectedOrganisation] =
    useState<SelectOption>();

  const debouncedSearch = useDebouncedValue(search, 500);

  const {
    sessions,
    isLoading,
    mutate,
    error: errorSessions,
  } = useGetSessions(debouncedSearch);

  const { showOverlay, hideOverlay } = useUtilities();

  const { trigger: runSessionApi, isMutating: isRunMutating } =
    useMutation<Session>('sessions', {
      onSuccess: (data) => {
        showSnackBar({
          text: `${data.name} has been started.`,
          variant: 'success',
          actionButtonText: 'Dismiss',
        });
        navigate(APP_URI.SESSIONS);

        if (sessions) {
          mutate({
            data:
              sessions?.map((curr) => (data.id === curr.id ? data : curr)) ||
              [],
          });
        }
      },
    });

  const { showSnackBar } = useUtilities();
  const navigate = useNavigate();

  const { sessionId: activeSessionId } = useParams();

  const organisationsIsIncludedMap: Map<string, boolean> | undefined = useMemo(
    () =>
      sessions
        ?.find(({ id }) => id === activeSessionId)
        ?.settings.reduce(
          (acc: Map<string, boolean>, curr) =>
            acc.set(curr.organisationId, curr.isIncluded),
          new Map<string, boolean>()
        ),
    [sessions, activeSessionId]
  );

  const {
    organisationTree,
    isLoading: isLoadingOrganisationTree,
    error: errorOrganisationTree,
  } = useOrganisationTree('sessions', organisationsIsIncludedMap);

  const removeSession = useCallback((sessionName: string) => {}, []);

  const runSession = useCallback(
    (sessionId: string, data: RunSessionData) => {
      runSessionApi({
        url: API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.CAMPAIGN_SESSION_RUN(
          sessionId
        ),
        method: 'POST',
        data,
      });
    },
    [runSessionApi]
  );

  useEffect(() => {
    isRunMutating || isLoadingOrganisationTree
      ? showOverlay('body', 'center', <Loading />)
      : hideOverlay('body');
  }, [hideOverlay, isRunMutating, showOverlay, isLoadingOrganisationTree]);

  return (
    <SessionsDataContext.Provider
      value={{
        sessions: sessions || [],
        search,
        selectedOrganisation,
        setSearch,
        setSelectedOrganisation,
        removeSession,
        runSession,
        error: !!errorSessions || errorOrganisationTree,
        isLoading: isLoading || isLoadingOrganisationTree,
        isRunMutating,
        organisationTree,
      }}
    >
      {children}
    </SessionsDataContext.Provider>
  );
};

const useSessionProvider = (): SessionContextProps =>
  useContext(SessionsDataContext);

export { SessionProvider, useSessionProvider };
