import { Notification, USER_ROLE } from 'models';
import { FC, PropsWithChildren } from 'react';
import ProfileNotifications from 'routes/components/ProfileNotifications';

export type UserNotificationsProps = PropsWithChildren<{}>;

const notifications: Array<Notification> = [
  {
    id: '1',
    isRead: false,
    name: 'Maria Gomez',
    action: 'assign',
    assignedForm: 'Greenhouse Gas Emissions',
    userRole: {
      id: '1',
      color: '#FF5722',
      name: USER_ROLE.SUPER_ADMIN,
    },
  },
  {
    id: '2',
    isRead: true,
    name: 'Maria Gomez',
    updatedRole: {
      id: '0b4f6056-26c2-45ff-b01f-a616297dab8c',
      name: 'org admin',
      color: '#2196F3',
    },
    action: 'role-update',
    userRole: {
      id: '1',
      color: '#FF5722',
      name: USER_ROLE.SUPER_ADMIN,
    },
  },
  {
    id: '3',
    isRead: true,
    name: 'Maria Gomez',
    action: 'mark',
    markedAs: 'To Review',
    marked: 'nesto',
    userRole: {
      id: '1',
      color: '#FF5722',
      name: USER_ROLE.SUPER_ADMIN,
    },
  },
];

const userNotifications = [
  {
    date: new Date(),
    notifications,
  },
];

const UserNotifications: FC<UserNotificationsProps> = (props) => {
  return <ProfileNotifications notifications={userNotifications} />;
};

export default UserNotifications;
