import { useUtilities } from '@faxi/web-component-library';
import { useCallback } from 'react';

import { Session } from '../../../models';
import { API_ROUTES } from '../../routes';
import useMutation from '../mutations/useMutation';

const useCreateSession = ({ onSuccess }: { onSuccess?: Function }) => {
  const { showSnackBar } = useUtilities();

  const { trigger, isMutating } = useMutation<{ data: Session }>(
    API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.BASE(),
    {
      onSuccess: ({ data }) => {
        showSnackBar({
          text: `${data.name} has been created.`,
          variant: 'success',
          actionButtonText: 'Dismiss',
        });

        onSuccess?.();
      },
      onError: (error) => {
        console.error('Error while creating session:', error);
        showSnackBar({
          variant: 'error',
          text: 'Failed to create campaign. Please try again.',
          actionButtonText: 'Dismiss',
        });
      },
    }
  );

  const createSession = useCallback(
    (data: Pick<Session, 'name' | 'description' | 'campaignId'>) =>
      trigger({ method: 'POST', data }),
    [trigger]
  );

  return { createSession, isMutating };
};

export default useCreateSession;
