import { useUtilities } from '@faxi/web-component-library';
import { FormStatus, SessionTree } from 'models';
import { useCallback, useMemo } from 'react';

import api, { useGetCampaignSessionForm } from '../api';
import { API_ROUTES } from '../api/routes';
import { generatePreviewFieldName } from '../utils';

type FormModulesProps = {
  sessionId?: string;
  collectionId?: string;
  asAdmin?: boolean;
};

export default function useFormModules(props: FormModulesProps) {
  const { sessionId, collectionId, asAdmin } = props;

  const { showSnackBar } = useUtilities();

  const {
    mutate: mutateSessionForm,
    progress,
    elements = [],
    sessionFormId,
    status: formStatus,
    approval: formApproval,
    isLoading: loadingForm,
  } = useGetCampaignSessionForm(sessionId, collectionId);

  const { trigger: updateStatus, isMutating: loadingStatus } = api.useMutation(
    API_ROUTES.SESSIONS[asAdmin ? 'FORM_APPROVAL' : 'FORM_STATUS'](
      sessionFormId
    ),
    {
      onSuccess: () =>
        showSnackBar({
          variant: 'success',
          text: 'Successfully updated form status.',
          actionButtonText: 'Dismiss',
        }),
    }
  );

  const modules = useMemo(
    () =>
      elements
        ?.sort((moduleA, moduleB) => moduleA.index - moduleB.index)
        .map((module) => ({
          ...module,
          name: generatePreviewFieldName(module.id, module.type),
        })),
    [elements]
  );

  const formData = useMemo(
    () =>
      modules?.reduce((data, module) => {
        if (['heading', 'text-box', 'divider'].includes(module.type))
          return data;
        return {
          ...(data as any),
          [module?.name]:
            module?.value || (module as any).config?.defaultValue || '',
        };
      }, undefined),
    [modules]
  );

  const updateFormStatus = useCallback(
    async (status: FormStatus) => {
      const data = await updateStatus({
        method: 'POST',
        data: { [asAdmin ? 'approval' : 'status']: status },
      });

      mutateSessionForm(
        (old: SessionTree) => ({
          ...old,
          status: data.status,
          approval: data.approval,
          progress: data.progress,
        }),
        { revalidate: false }
      );
    },
    [asAdmin, mutateSessionForm, updateStatus]
  );

  return {
    modules,
    progress,
    formData,
    formStatus,
    formApproval,
    loadingForm,
    loadingStatus,
    sessionFormId,
    updateFormStatus,
    mutateSessionForm,
  };
}
