import styled from 'styled-components';

const StyledFormulaElement = styled.div`
  &.esg-formula-element {
    &--error {
      color: var(--ALERT_ERROR_1_1);
    }
  }
`;

export { StyledFormulaElement };
