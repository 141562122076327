import { Campaign } from 'models';
import useSWR, { SWRConfiguration } from 'swr';

import { API_ROUTES } from '../routes';

const useGetCampaignTree = (
  id?: string,
  shouldFetch?: boolean,
  config?: SWRConfiguration
) => {
  const { data, error, ...rest } = useSWR<{ data: Campaign }>(
    shouldFetch && id
      ? API_ROUTES.CAMPAIGN_ITEMS_ROUTES.CAMPAIGN_TREE(id)
      : null,
    config
  );

  return {
    campaign: data?.data,
    isError: error,
    ...rest,
  };
};

export default useGetCampaignTree;
