import { ButtonProps } from '@faxi/web-component-library';
import { FC } from 'react';

import Icon from '../../../../components/Icon';
import UsersPreview from '../../../Users/components/UsersPreview.component';
import SessionHeader from '../SessionHeader';
import { members } from './data';
import { StyledSessionMembers } from './SessionMembers.styled';

export type SessionMembersProps = {};

const menuItems: ButtonProps[] = [
  {
    children: 'Edit permissions',
    icon: <Icon name="pen" />,
    variant: 'ghost',
    iconPosition: 'left',
    onClick: (e) => {
      e.stopPropagation();
    },
  },
  {
    children: 'Kick',
    icon: <Icon name="trash-can" className="color-secondary" />,
    iconPosition: 'left',
    variant: 'delete-ghost',
    onClick: (e) => {
      e.stopPropagation();
    },
  },
];

const SessionMembers: FC<SessionMembersProps> = () => {
  return (
    <StyledSessionMembers className="esg-session-members">
      <SessionHeader heading="Members" />
      <div className="esg-session-members__content">
        <UsersPreview
          users={members}
          view={'grid'}
          menuItems={menuItems}
          includeDefaultMenuItems={false}
        />
      </div>
    </StyledSessionMembers>
  );
};

export default SessionMembers;
