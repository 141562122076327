import env from 'env';

const apiVersion = env.VITE_API_VERSION;

const baseUrl = `/campaign-sessions/${apiVersion}`;

export const CAMPAIGN_SESSIONS_ROUTES = {
  BASE: (search?: string) =>
    `${baseUrl}${search ? '?searchString=' + search : ''}`,

  // TODO: why do we even send org id, when it is attached to user?
  ORGANISATION_CAMPAIGN_SESSIONS: (id: string, search: string) =>
    `${baseUrl}/organisations/${id}${search ? '?searchString=' + search : ''}`,

  SESSION: (sessionId: string) => `${baseUrl}/${sessionId}`,

  CAMPAIGN_SESSION_ORGANISATIONS_TREE: `/organisations/${apiVersion}/tree`,

  CAMPAIGN_SESSION_FORM: (sessionId: string, formId: string) =>
    `${baseUrl}/${sessionId}/forms/${formId}`,

  CAMPAIGN_SESSION_RUN: (id: string) => `${baseUrl}/${id}/run`,

  CAMPAIGN_SESSION_SUBMIT_FORM: (companyId: string) =>
    `${baseUrl}/${companyId}/submit-form`,

  CAMPAIGN_SESSION_INCLUDE: (sessionId: string, companyId: string) =>
    `${baseUrl}/${sessionId}/organisations/${companyId}/include`,

  CAMPAIGN_SESSION_EXCLUDE: (sessionId: string, companyId: string) =>
    `${baseUrl}/${sessionId}/organisations/${companyId}/exclude`,
};
