import {
  CheckboxProps,
  InputProps,
  RadioGroupOption,
  SelectOption,
  Tag,
} from '@faxi/web-component-library';
import { Dayjs } from 'dayjs';
import { FC, FunctionComponent, PropsWithChildren, ReactNode } from 'react';

import { BaseModel } from './BaseModel';

export type InlineModuleElement<T> = T &
  Pick<InlineEditableType, 'value' | 'onSave' | 'onChange' | 'className'> & {
    showIcon?: boolean;
    moduleElement?: FunctionComponent<ModuleElementType<DataModuleEnum>>;
  };

export type ModuleElementType<T extends DataModuleEnum> = PropsWithChildren<{
  type: T;
  className?: string;
  module: ModuleConfig<ModuleConfigType, T>;
  elements?: ModuleConfig<ModuleConfigType, T>[];
  modulesType?: 'canvas-module' | 'preview-module';
  onTitleSave?: (module: IDataModule<T>) => void;
  moduleElement?: FunctionComponent<ModuleElementType<DataModuleEnum>>;
}>;

export interface IDataModule<T = DataModuleEnum> extends BaseModel {
  type: T;
  title: string;
  index: number;
  elementId?: string;
  createdBy?: string;
  updatedBy?: string;
  dataCollectionFormId?: string;
  sessionId?: string;
  topic?: string;
  conditionalElements?: Record<string, any>;
  hasConditions?: boolean;
  onRemoveModule?: (v: string) => void;
  renderModuleField?: (fieldName: string) => ReactNode;
  initialData?: Record<string, any>;
  elements?: ModuleConfig<ModuleConfigType, T>[];
  moduleElement?: FC<ModuleElementType<DataModuleEnum>>;
}
export enum DataModuleEnum {
  SECTION = 'section',
  TEXT_BOX = 'text-box',
  SWITCH = 'switch',
  UPLOAD = 'upload',
  INPUT = 'input',
  CHOICE = 'choice',
  FORMULA = 'formula',
  CHECKLIST = 'checklist',
  DIVIDER = 'divider',
  INTEGRATION = 'integration',
  DROPDOWN = 'dropdown',
}

export const mapStringToDataModuleEnum = (
  val: string
): keyof typeof DataModuleEnum => {
  switch (val) {
    case DataModuleEnum.TEXT_BOX:
      return 'TEXT_BOX';
    case DataModuleEnum.SWITCH:
      return 'SWITCH';
    case DataModuleEnum.UPLOAD:
      return 'UPLOAD';
    case DataModuleEnum.INPUT:
      return 'INPUT';
    case DataModuleEnum.CHOICE:
      return 'CHOICE';
    case DataModuleEnum.CHECKLIST:
      return 'CHECKLIST';
    case DataModuleEnum.DIVIDER:
      return 'DIVIDER';
    case DataModuleEnum.INTEGRATION:
      return 'INTEGRATION';
    case DataModuleEnum.DROPDOWN:
      return 'DROPDOWN';

    default:
      return 'TEXT_BOX';
  }
};

export type DraggingModuleConfig = {
  type: 'data-module' | 'canvas-module';

  dataModuleType?: DataModuleEnum;

  canvasModuleDetails?: IDataModule<DataModuleEnum>;
};

export type InlineEditableType = PropsWithChildren<{
  onSave: (text?: string) => void;
  onDiscard?: () => void;
  placeholderText: string;
}> &
  InputProps &
  Pick<IDataModule, 'conditionalElements' | 'hasConditions'> & {
    // TODO
    moduleElement?: FunctionComponent<any>;
  };

export type ModuleMapperType = {
  [K in DataModuleEnum]: FC<
    IDataModule<K> & Pick<InlineEditableType, 'onSave'>
  >;
};

export type ModuleConfigMapperType<T = any> = Partial<{
  [K in DataModuleEnum]: FC<ModuleConfig<T, K>> | null;
}>;

//non-configurable
export type DividerDataModule = IDataModule<DataModuleEnum.DIVIDER>;

export type TextConfig = {
  textFormat: 'heading' | 'title' | 'subtitle' | 'body-text';
};

//configurable
export type TextDataModule = ModuleConfig<TextConfig, DataModuleEnum.TEXT_BOX>;

//configurable
export type InputDataModule = ModuleConfig<
  InputModuleConfig,
  DataModuleEnum.INPUT
>;
export type InputPreviewType = InputDataModule & {
  renderFormField?: boolean;
};

//configurable
export type FormulaDataModule = ModuleConfig<
  FormulaModuleConfig,
  DataModuleEnum.FORMULA
>;

/* ------ */

export type UploadModuleConfig = {
  file?: File;
  xbrl?: string;
};

//configurable
export type UploadDataModule = ModuleConfig<
  UploadModuleConfig,
  DataModuleEnum.UPLOAD
>;

/* ------ */

export type SwitchModuleConfig = {
  defaultValue?: boolean;
  xbrl?: string;
};

//configurable
export type SwitchDataModule = ModuleConfig<
  SwitchModuleConfig,
  DataModuleEnum.SWITCH
>;

/* ------ */

export type CheckListModuleConfig = {
  options: Array<Pick<CheckboxProps, 'label' | 'value'>>;
  defaultValues: Array<Pick<CheckboxProps, 'label' | 'value'>>;
  xbrl?: string;
};

//configurable
export type CheckListDataModule = ModuleConfig<
  CheckListModuleConfig,
  DataModuleEnum.CHECKLIST
>;

/* ------ */

//configurable
export type DropdownDataModule = ModuleConfig<
  DropdownModuleConfig,
  DataModuleEnum.DROPDOWN
>;

/* ------ */

export type IntegrationTypes = 'kinto' | 'jira' | 'slack';

//configurable
export type IntegrationDataModule = ModuleConfig<
  IntegrationModuleConfig,
  DataModuleEnum.INTEGRATION
>;

export type SectionModuleConfig = {
  elements?: IDataModule[];
};

//non-configurable
export type SectionDataModule = ModuleConfig<
  SectionModuleConfig,
  DataModuleEnum.SECTION
>;

/* ------ */

export type DataCollectionForm = {
  campaignId: string;
  dataCollectionFormId: string;
  elements: Pick<
    ModuleConfig<ModuleConfigType, DataModuleEnum>,
    'elementId' | 'value' | 'type'
  >[];
};

export type ModuleConfig<T, S> = IDataModule<S> & {
  config?: T;
  value?: any;
  isNew?: boolean;
};
export enum InputFieldTypes {
  SIMPLE_FIELD = 'simple-field',
  NUMERIC_FIELD = 'numeric-field',
  EMAIL_FIELD = 'email-field',
  DATE_FIELD = 'date-field',
}

export type InputModuleConfig = {
  type: InputFieldTypes;
  unit?: string;
  helperText?: string;
  placeholder?: string;
  minDate?: Dayjs | string;
  maxDate?: Dayjs | string;
  xbrl?: string;
};

export type ModuleConfigType =
  | InputModuleConfig
  | DropdownModuleConfig
  | ChoiceModuleConfig
  | CheckListModuleConfig
  | IntegrationModuleConfig;

export type DropdownModuleConfig = {
  other?: boolean;
  placeholder?: string;
  helperText?: string;
  defaultValue?: string;
  options: Array<SelectOption<string>>;
  xbrl?: string;
};
export type ChoiceModuleConfig = {
  defaultValue?: string;
  options: Array<Pick<RadioGroupOption, 'label' | 'value'>>;
  xbrl?: string;
};

export type ChoiceDataModule = ModuleConfig<
  ChoiceModuleConfig,
  DataModuleEnum.CHOICE
>;

export type IntegrationModuleConfig = {
  value: string;
  integration: IntegrationTypes;
  xbrl?: string;
};

export type FormulaModuleConfig = {
  formula: Tag[];
};
