import { User } from 'models';

import { USER_FILED_TO_ROLE_MAPPER } from '../constants';
import { UserFieldKey } from '../types';

//This hook provides basic user and role information for profile details page
const useUserInformation = (user?: User) => {
  if (!user) return {};

  const { roles, id, organisation, ...rest } = user;

  const userInformation = {
    ...rest,
    ...(organisation?.name && { organisation: organisation.name }),
  };
  const role = roles[0];

  return {
    roleInformation: { label: 'Role', value: role },
    userInformation: Object.entries(userInformation).map(([key, value]) => ({
      label: USER_FILED_TO_ROLE_MAPPER[key as UserFieldKey],
      value,
    })),
  };
};

export default useUserInformation;
