import useSWR from 'swr';

import { Session } from '../../../models';
import { API_ROUTES } from '../../routes';

const useGetSessions = (search = '') => {
  const { data, ...rest } = useSWR<{
    data: Session[];
  }>(API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.BASE(search));
  return {
    sessions: data?.data,
    ...rest,
  };
};

export default useGetSessions;
