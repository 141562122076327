import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledFormulaPreview = styled.div`
  ${flex('row', 'space-between', 'center')};

  .esg-formula-preview {
    &__label {
      ${fontSize(theme.fontSizes.FONT_14)};
    }
  }
`;

export { StyledFormulaPreview };
