import { TagsInput, TagsInputProps } from '@faxi/web-component-library';
import { FieldProps } from '@faxi/web-form';
import { FC } from 'react';

import { INameExtended } from '../../Icon';

type ValueOnChange = (event: string[]) => void;

export type TagsInputFieldProps = FieldProps<string[], ValueOnChange> &
  TagsInputProps<INameExtended>;

const TagsInputField: FC<TagsInputFieldProps> = (props) => {
  const { error, dirty, touched, value, ...rest } = props;

  return (
    <TagsInput
      className="esg-tags-input-field"
      initialTags={value as any}
      errorText={error as string}
      {...rest}
    />
  );
};

export default TagsInputField;
