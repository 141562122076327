import { GlowScroll } from '@faxi/web-component-library';
import { BreadcrumbsProps } from '@faxi/web-component-library/build/components/_molecules/Breadcrumbs/Breadcrumbs.component';
import classNames from 'classnames';
import {
  BasicTreeView,
  FolderDescriptionProps,
  FolderNavHeader,
  Loading,
} from 'components';
import { FC, PropsWithChildren } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { BasicTreeViewProps } from '../../components/_molecules/BasicTreeView/BasicTreeView.component';
import CollapsibleSidePanel from '../../components/_molecules/CollapsibleSidePanel';
import { BlockUI } from '../../helpers';
import { StyledFoldersLayout } from './FoldersLayout.styled';

export type FoldersLayoutProps = PropsWithChildren<
  {
    pageTitle: string;
    treeNodeTitle?: string;
    treeViewTitle?: string;
    className?: string;
    loadingTree: boolean;
  } & FolderDescriptionProps &
    Pick<BreadcrumbsProps, 'crumbs'> &
    Pick<
      BasicTreeViewProps,
      | 'data'
      | 'withMenu'
      | 'activeNodeId'
      | 'onCheck'
      | 'onDelete'
      | 'onDuplicate'
    >
>;

const FoldersLayout: FC<FoldersLayoutProps> = (props) => {
  const {
    data,
    pageTitle,
    treeViewTitle,
    treeNodeTitle,
    loadingTree,
    crumbs,
    activeNodeId,
    className,
    ...rest
  } = props;

  const { organisation } = useParams<{ organisation: string }>();

  return (
    <StyledFoldersLayout
      className={classNames('esg-folders-layout', className)}
      title={pageTitle}
      padding={0}
    >
      <CollapsibleSidePanel
        expandDirection="right"
        className="esg-folders-layout__tree-view"
      >
        <p className="esg-folders-layout__tree-view__title">{treeViewTitle}</p>
        {data ? (
          <BasicTreeView
            withMenu
            data={{
              ...data,
              canDelete: data.id !== organisation,
            }}
            showRootNode={false}
            activeNodeId={activeNodeId}
            className="esg-folders-layout__basic-tree"
            {...rest}
          />
        ) : (
          <Loading />
        )}
      </CollapsibleSidePanel>

      <BlockUI loading={loadingTree} fallbackCondition={!data}>
        <GlowScroll variant="gray">
          <div className="esg-folders-layout__content">
            <FolderNavHeader
              crumbs={crumbs}
              title={treeNodeTitle || ''}
              updateDescription={rest.onChange}
              {...rest}
              icon="building"
            />

            <Outlet />
          </div>
        </GlowScroll>
      </BlockUI>
    </StyledFoldersLayout>
  );
};

export default FoldersLayout;
