import { Image } from '@faxi/web-component-library';
import {
  actionLabels,
  AssignNotification,
  MarkNotification,
  Notification,
  RoleUpdateNotification,
} from 'models';
import { FC, Fragment, PropsWithChildren, useCallback } from 'react';

import { BasicTag } from '../../../components';
import { ApprovalChip } from '../../../components/_molecules/ApprovalProgressBar/components';
import DataGridPreview from '../../../components/_molecules/DataGridPreview';
import Icon from '../../../components/Icon';
import { ROLE_VALUE_TO_DESCRIPTION_MAPPER } from '../../../pages/Users/constants';
import StatusFlag from './components/StatusFlag';
import { StyledNotificationsGrid } from './NotificationsGrid.styled';

const isAssignNotification = (
  notification: Notification
): notification is AssignNotification => {
  return notification.action === 'assign';
};

const isRoleUpdateNotification = (
  notification: Notification
): notification is RoleUpdateNotification => {
  return notification.action === 'role-update';
};

const isMarkNotification = (
  notification: Notification
): notification is MarkNotification => {
  return notification.action === 'mark';
};

export type NotificationsGridProps = PropsWithChildren<{
  notifications: Notification[];
}>;

const NotificationsGrid: FC<NotificationsGridProps> = ({ notifications }) => {
  const renderNotifications = useCallback(
    (notification: Notification) => [
      <Fragment>
        <StatusFlag initialIsRead={notification.isRead} />
        <div className="user-image">
          <div className="user-image-container">
            {notification.img ? (
              <Image src={notification.img} alt={notification.name} />
            ) : (
              <Icon name="user-solid" />
            )}
          </div>
        </div>
        {notification.name}
        <BasicTag
          text={ROLE_VALUE_TO_DESCRIPTION_MAPPER[notification.userRole.name]}
          color={notification.userRole.color}
        />
      </Fragment>,
      <div>{actionLabels[notification.action]}</div>,
      <div className="esg-notifications-grid__container__column">
        {isAssignNotification(notification) ? (
          <Fragment>
            <Icon
              className="esg-session-card__container__column__icon"
              name="file"
            />
            {notification.assignedForm}
          </Fragment>
        ) : isMarkNotification(notification) ? (
          <Fragment>
            <Icon
              className="esg-session-card__container__column__icon"
              name="file"
            />
            {notification.marked}
          </Fragment>
        ) : isRoleUpdateNotification(notification) ? (
          <BasicTag
            text={
              ROLE_VALUE_TO_DESCRIPTION_MAPPER[notification.updatedRole.name]
            }
            color={notification.updatedRole.color}
          />
        ) : null}
      </div>,
      <>
        {isMarkNotification(notification) && (
          <div className="esg-notifications-grid__container__column">
            <span>As</span>
            <ApprovalChip status={notification.markedAs} />
          </div>
        )}
      </>,
    ],
    []
  );

  return (
    <StyledNotificationsGrid className="esg-notifications-grid">
      <DataGridPreview
        data={notifications}
        renderColumns={renderNotifications}
      />
    </StyledNotificationsGrid>
  );
};

export default NotificationsGrid;
