import classNames from 'classnames';
import Icon from 'components/Icon';
import { Session } from 'models';
import { FC, memo, PropsWithChildren } from 'react';

import ApprovalProgressBar from '../ApprovalProgressBar';
import { StyledSessionCard } from './SessionCard.styled';

export type SessionCardProps = PropsWithChildren<{
  session: Session;
  onSelect: () => void;
  className?: string;
}>;

const SessionCard: FC<SessionCardProps> = ({
  session,
  onSelect,
  className,
}) => (
  <StyledSessionCard
    onClick={onSelect}
    className={classNames('esg-session-card', 'hover-effect', className)}
  >
    <div
      className={classNames(
        'esg-session-card__column',
        'esg-session-card__column--name'
      )}
    >
      <strong className="esg-session-card__column__name">
        <Icon className="esg-session-card__column__name__icon" name="file" />
        <span>{session.name}</span>
      </strong>
      <p className="esg-session-card__column__description">
        {session.description || '-'}
      </p>
    </div>

    <div
      className={classNames(
        'esg-session-card__column',
        'esg-session-card__column--status'
      )}
    >
      {/* TODO */}
      <ApprovalProgressBar title="Progress" status="To Review" />
      <ApprovalProgressBar title="Approval" status="To Review" />
    </div>
  </StyledSessionCard>
);

export default memo(SessionCard);
