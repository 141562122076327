import {
  DataModuleEnum,
  IDataModule,
  InlineModuleElement,
  ModuleElementType,
  ModuleMapperType,
} from 'models';
import { FC, memo, useMemo, useState } from 'react';

import moduleMapper from '../../../../../utils/moduleMapper';
import modulePreviewMapper from '../../../../../utils/modulePreviewMapper';

const ModuleElement: FC<ModuleElementType<DataModuleEnum>> = (props) => {
  const {
    className,
    type,
    module,
    modulesType = 'canvas-module',
    onTitleSave,
  } = props;

  const mapper = useMemo<ModuleMapperType>(
    () =>
      modulesType === 'canvas-module' ? moduleMapper : modulePreviewMapper,
    [modulesType]
  );

  const Module = useMemo(
    () => mapper[type] as FC<InlineModuleElement<IDataModule<typeof type>>>,
    [mapper, type]
  );

  const { isNew, createdBy, updatedBy, ...rest } = module;

  const [value, setValue] = useState(module.title);

  return (
    <Module
      {...rest}
      value={value}
      onChange={setValue}
      className={className}
      onSave={(title?: string) => {
        onTitleSave?.({ ...module, title: title as string });
      }}
      moduleElement={ModuleElement}
    />
  );
};

export default memo(ModuleElement);
