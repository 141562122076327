import { TreeNodeElement } from '../models';

export const createTreeWithParentReferences = (
  node: TreeNodeElement,
  parent: TreeNodeElement | null = null
): TreeNodeElement => {
  node.parent = parent || null;
  // node.checked = defaultChecked || 0;
  node.children = node.children?.map((child) =>
    createTreeWithParentReferences(child, node)
  );

  return node;
};
