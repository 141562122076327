import { DataModuleEnum, ModuleConfigMapperType } from 'models';

import BaseCheckListFieldConfiguration from './BaseCheckListFieldConfiguration';
import BaseChoiceFieldConfiguration from './BaseChoiceFieldConfiguration';
import BaseDropdownFieldConfiguration from './BaseDropdownFieldConfiguration';
import BaseFormulaFieldConfiguration from './BaseFormulaFieldConfiguration';
import BaseInputFieldConfiguration from './BaseInputFieldConfiguration';
import BaseIntegrationFieldConfiguration from './BaseIntegrationFieldConfiguration';
import BaseSwitchFieldConfiguration from './BaseSwitchFieldConfiguration';
import BaseTextFieldConfiguration from './BaseTextFieldConfiguration';
import BaseUploadFieldConfiguration from './BaseUploadFieldConfiguration';

export const BASE_CONFIGURABLE_MODULES: ModuleConfigMapperType = {
  [DataModuleEnum.CHECKLIST]: BaseCheckListFieldConfiguration,
  [DataModuleEnum.INPUT]: BaseInputFieldConfiguration,
  [DataModuleEnum.CHOICE]: BaseChoiceFieldConfiguration,
  [DataModuleEnum.DROPDOWN]: BaseDropdownFieldConfiguration,
  [DataModuleEnum.SWITCH]: BaseSwitchFieldConfiguration,
  [DataModuleEnum.INTEGRATION]: BaseIntegrationFieldConfiguration,
  [DataModuleEnum.UPLOAD]: BaseUploadFieldConfiguration,
  [DataModuleEnum.TEXT_BOX]: BaseTextFieldConfiguration,
  [DataModuleEnum.FORMULA]: BaseFormulaFieldConfiguration,
};
