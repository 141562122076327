import { theme } from '@faxi/web-component-library';
import styled from 'styled-components';

const StyledNotificationsGrid = styled.div`
  .esg-notifications-grid {
    .esg-data-grid-preview {
      grid-template-columns:
        minmax(${theme.sizes.SIZE_288}, ${theme.sizes.SIZE_320}) minmax(
          ${theme.sizes.SIZE_164},
          ${theme.sizes.SIZE_192}
        )
        minmax(${theme.sizes.SIZE_248}, ${theme.sizes.SIZE_248})
        minmax(${theme.sizes.SIZE_288}, ${theme.sizes.SIZE_320});
    }
  }
`;

export { StyledNotificationsGrid };
