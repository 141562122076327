import { CompanyCampaignsAPI } from 'models';
import useSWR, { SWRConfiguration } from 'swr';

import { API_ROUTES } from '../routes';

const useGetCampaignSessionTree = (config?: SWRConfiguration) =>
  useSWR<{ data: CompanyCampaignsAPI }, Error>(
    `${API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.CAMPAIGN_SESSION_ORGANISATIONS_TREE}?populateCampaign=true`,
    config
  );

export default useGetCampaignSessionTree;
