import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import { BaseLayout } from 'layouts';
import styled from 'styled-components';

const StyledSubheader = styled.p`
  ${fontSize(theme.fontSizes.FONT_14)};
  color: var(--GRAY-70);
  margin: 0;
`;

const StyledRunSession = styled(BaseLayout)`
  .esg-base-layout__content {
    padding: ${theme.sizes.SIZE_32};
  }

  .company-checkbox-label {
    ${flex('row', 'flex-start', 'center')};
    ${fontSize(theme.fontSizes.FONT_20)};
    gap: ${theme.sizes.SIZE_8};
    height: ${theme.sizes.SIZE_32};
    font-weight: 600;
    margin: 0;
  }

  .esg-run-session {
    &__form {
      ${flex('column')};
    }

    &__content {
      ${flex('row', 'flex-start', 'stretch')};
      overflow: auto;
      margin: ${theme.sizes.SIZE_32} 0;

      &__vertical {
        flex: 0;
        border-left: 1px solid var(--GRAY-EF);
      }

      &__container {
        ${flex('column', 'flex-start', 'flex-start')};
        gap: ${theme.sizes.SIZE_40};
        ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};
        overflow: auto;

        &--left {
          padding-right: ${theme.sizes.SIZE_32};
        }

        &--right {
          padding-left: ${theme.sizes.SIZE_32};
        }
      }

      &__header {
        ${flex('row', 'flex-start', 'center')};
        gap: ${theme.sizes.SIZE_10};
        font-weight: 600;
        color: var(--GRAY--54);
        height: ${theme.sizes.SIZE_32};

        &__button {
          ${flex('row', 'center', 'center')};
          gap: ${theme.sizes.SIZE_10};
          font-weight: 400;
          background-color: var(--GRAY-EF);
          border: solid 1px var(--GRAY-98);
          border-radius: ${theme.sizes.SIZE_8};
          padding: ${theme.sizes.SIZE_4} ${theme.sizes.SIZE_12};
        }
      }
    }

    &__dates {
      padding: ${theme.sizes.SIZE_24} ${theme.sizes.SIZE_12};
      border-bottom: 1px solid var(--GRAY-EF);

      &__container {
        ${flex('row', 'flex-start', 'flex-start')};
        gap: ${theme.sizes.SIZE_40};
        margin-top: ${theme.sizes.SIZE_12};
      }
    }

    &__buttons {
      padding: ${theme.sizes.SIZE_24} ${theme.sizes.SIZE_12};
      ${flex('row', 'flex-start', 'flex-start')};
      gap: ${theme.sizes.SIZE_40};
    }

    &__subtitle {
      margin: ${theme.sizes.SIZE_40} 0;
    }
  }
`;

export { StyledRunSession, StyledSubheader };
