import { FORM_APPROVAL, FORM_STATUS } from 'models';
import { useMemo } from 'react';

import { mockProgressAndApproval } from '../utils';

const DEFAULT_SESSION_REPORT_APPROVAL_DATA = {
  icon: 'bars-progress-solid',
  name: 'Approval Status',
  description: 'by Company',
};

const DEFAULT_ORGANISATION_GRID_ELEMENT = {
  icon: '/assets/svg/toyota_dark.svg',
  name: 'Toyota Belgium',
  description: '',
  items: [
    {
      icon: 'folder',
      name: 'Environment',
      currentStep: 17,
      numberOfSteps: 100,
      status: FORM_APPROVAL.APPROVED,
    },
    {
      icon: 'folder',
      name: 'Social',
      currentStep: 87,
      numberOfSteps: 100,
      status: FORM_STATUS.IN_PROGRESS,
    },
    {
      icon: 'folder',
      name: 'Governance',
      currentStep: 0,
      numberOfSteps: 1,
      status: FORM_APPROVAL.TO_REVIEW,
    },
  ],
};

const useSessionDashboardGridCards = () => {
  const generateApprovalGridElement = useMemo(() => {
    return {
      ...DEFAULT_SESSION_REPORT_APPROVAL_DATA,
      items: [
        {
          ...mockProgressAndApproval(),
          icon: '/assets/svg/toyota_dark.svg',
          name: 'Toyota Belgium',
        },
      ],
    };
  }, []);

  return {
    data: [generateApprovalGridElement, DEFAULT_ORGANISATION_GRID_ELEMENT],
  };
};

export default useSessionDashboardGridCards;
