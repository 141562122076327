import { isAxiosError } from 'axios';

export const DEFAULT_ERROR_MESSAGE =
  'An error occurred, please try again later.';

/**
 * This utility function retrieves an error message from an Axios error
 * or returns a general error message.
 */
export const getErrorMessage = (error: unknown): string => {
  if (isAxiosError(error)) {
    if (Array.isArray(error?.response?.data?.error)) {
      return (
        error?.response?.data?.error?.[0]?.message ?? DEFAULT_ERROR_MESSAGE
      );
    } else {
      const { message, error: errorMessage } = error?.response?.data ?? {};

      return message ?? errorMessage ?? DEFAULT_ERROR_MESSAGE;
    }
  }

  return DEFAULT_ERROR_MESSAGE;
};
