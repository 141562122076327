import { elevate_l, theme } from '@faxi/web-component-library';
import { flex, fontSize, position, size } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

interface StyledSectionCardProps {
  $view: 'grid' | 'list';
}

const StyledSectionCard = styled.div<StyledSectionCardProps>`
  ${elevate_l};

  ${flex('column', 'space-between', 'flex-start')};
  width: 100%;
  cursor: pointer;
  position: relative;
  border: ${theme.sizes.SIZE_1} solid var(--SHADE_1_8);
  padding: ${theme.sizes.SIZE_24};
  border-radius: ${theme.sizes.SIZE_8};
  gap: ${theme.sizes.SIZE_32};
  min-height: ${theme.sizes.SIZE_112};

  ${(props) =>
    props.$view === 'list'
      ? css`
          min-width: ${theme.sizes.SIZE_320};
        `
      : css`
          align-items: center;
        `};

  &.hover-effect {
    transition: unset !important;
  }

  .esg-section-card {
    &__menu {
      z-index: 1;
      ${position(
        'absolute',
        `top ${theme.sizes.SIZE_16} right ${theme.sizes.SIZE_16}`
      )};
    }

    .wcl-icon {
      ${fontSize(theme.fontSizes.FONT_16)};
    }

    &__content {
      ${(props) =>
        props.$view === 'list'
          ? css`
              ${flex('row', 'flex-start', 'center')};
            `
          : css`
              ${flex('column', 'center', 'center')};
              text-align: center;
            `};

      width: 100%;
      gap: ${theme.sizes.SIZE_12};
      ${fontSize(theme.fontSizes.FONT_24, theme.sizes.SIZE_32)};
      font-weight: 600;
      padding-right: ${theme.sizes.SIZE_24};

      &__icon {
        ${size(theme.sizes.SIZE_56, theme.sizes.SIZE_48)}
        min-width: ${theme.sizes.SIZE_56};
        border-radius: ${theme.sizes.SIZE_8};
        background: linear-gradient(
          rgba(239, 240, 240, 0.5),
          rgba(217, 217, 217, 0.4)
        );

        color: var(--GRAY-40);
        ${flex('row', 'center', 'center')};
        ${fontSize(theme.sizes.SIZE_32)};
      }
    }

    &__description {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      ${(props) =>
        props.$view === 'list' &&
        css`
          ${flex('row', 'space-between', 'center')};
          width: 100%;
        `}
      .button--ghost {
        padding: ${theme.sizes.SIZE_8};
      }
    }

    &__delete-btn {
      height: fit-content;
      min-height: unset;
      ${position(
        'absolute',
        `bottom ${theme.sizes.SIZE_12} right ${theme.sizes.SIZE_12}`
      )}
    }
  }
`;

export { StyledSectionCard };
