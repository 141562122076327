import { CollapsibleSidePanel } from 'components';
import { FC, useCallback, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { BasicTreeView, Loading } from '../../components';
import { APP_URI } from '../../config';
import { BlockUI } from '../../helpers';
import { useDeleteCampaignItem, useNavigateUpOnDelete } from '../../hooks';
import authBus from '../../modules/authBus';
import { useCampaignProvider } from '../../providers/Campaign';
import BaseLayout from '../BaseLayout';

const CreateCampaignLayout: FC = () => {
  const { pathname } = useLocation();

  const needsTree = useMemo(() => pathname !== APP_URI.CAMPAIGNS, [pathname]);

  const { tree: root, isError } = useCampaignProvider();

  const { campaignItemsMutationRequest, deletedCampaign } =
    useCampaignProvider();

  const onDeleteSubmit = useCallback(
    async (id: string) => {
      deletedCampaign.current = true;
      await campaignItemsMutationRequest({ method: 'DELETE', params: { id } });
      authBus.broadcastEvent('delete_campaign_entity', { id });
    },
    [campaignItemsMutationRequest, deletedCampaign]
  );

  const { onDelete } = useDeleteCampaignItem(onDeleteSubmit);

  const { handleNavigate } = useNavigateUpOnDelete();

  return (
    <BlockUI error={isError}>
      <BaseLayout direction="row" padding={0} title="Campaigns">
        {needsTree && (
          <CollapsibleSidePanel expandDirection="right">
            {root ? (
              <BasicTreeView
                withMenu
                onDelete={async ({ id, name }) => {
                  await onDelete(id, name, () => {
                    handleNavigate(id);
                    deletedCampaign.current = false;
                  })();
                }}
                data={root}
              />
            ) : (
              <Loading />
            )}
          </CollapsibleSidePanel>
        )}

        <Outlet />
      </BaseLayout>
    </BlockUI>
  );
};

export default CreateCampaignLayout;
