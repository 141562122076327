import {
  Button,
  Divider,
  GlowScroll,
  ModalRef,
  useCallbackRef,
} from '@faxi/web-component-library';
import { Form, FormField, FormRef, validators } from '@faxi/web-form';
import classNames from 'classnames';
import { APP_URI } from 'config';
import { BlockUI } from 'helpers';
import cloneDeep from 'lodash.clonedeep';
import { CampaignItem, RunSessionData, TreeNodeElement } from 'models';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import api from '../../../../api';
import useGetSession from '../../../../api/hooks/sessions/useGetSession';
import {
  BasicTreeView,
  CalendarField,
  CheckboxTreeView,
  EmptyFolder,
  EntityFormModal,
  FolderNavHeader,
  Loading,
} from '../../../../components';
import Icon from '../../../../components/Icon';
import { useSessionProvider } from '../../../../providers/Session';
import {
  createTreeWithParentReferences,
  mapCampaignToTreeNodeElement,
} from '../../../../utils';
import { NewSessionForm } from '../../../Users/Users.page';
import { StyledRunSession } from './RunSession.styled';

const validations = {
  startDate: validators.general.required('Start date is required'),
  endDate: validators.general.required('Start date is required'),
  campaign: validators.general.required('Campaign is required'),
  companies: validators.general.required('Please select at least one row'),
};

const RunSession: FC = () => {
  const location = useLocation();

  const [searchParams] = useSearchParams();

  const { sessionId } = useParams<{
    sessionId: string;
  }>();

  const organisationId = useMemo(
    () => searchParams.get('organisationId'),
    [searchParams]
  );

  const { organisationTree } = useSessionProvider();

  const { session, isLoading } = useGetSession(sessionId!);

  const { campaignItem: campaignTreeData } = api.useGetCampaignItem(
    session && session.campaignId
  );

  const [campaignTree, setCampaignTree] = useState<TreeNodeElement>();

  const initialCampaignTree = useRef<TreeNodeElement>();

  const [form, formRef] = useCallbackRef<FormRef>();
  const modalRef = useRef<ModalRef>(null);

  useEffect(() => {
    const campaignTreeWithParentReferences =
      campaignTreeData &&
      createTreeWithParentReferences(
        mapCampaignToTreeNodeElement(campaignTreeData as CampaignItem),
        null
      );

    if (campaignTreeWithParentReferences) {
      const campaignTreeWithCheckedNodes = campaignTreeWithParentReferences;

      // TODO: toggle checkboxes by value in session
      // if (companyCampaign?.campaign) {
      //   campaignTreeWithCheckedNodes = toggleCheckByIds(
      //     campaignTreeWithParentReferences,
      //     companyCampaign.campaign.dataCollectionElements.map((el) => el.id)
      //   );
      // }

      setCampaignTree(campaignTreeWithCheckedNodes);

      initialCampaignTree.current = cloneDeep(campaignTreeWithCheckedNodes);
    }
  }, [campaignTreeData]);

  const handleRunSession = useCallback(
    async (values: RunSessionData & { campaign: string }) => {
      if (!sessionId) return;

      // console.log({ values });

      // runSession(sessionId, rest);
    },
    [sessionId]
  );

  const handleSubmit = useCallback(async (data: NewSessionForm) => {
    // const sessionData = {
    //   name: data.name,
    //   campaign: data.campaign,
    //   description: data.description,
    // };
    modalRef?.current?.close();
  }, []);

  return (
    <StyledRunSession
      direction="column"
      title="Sessions"
      className="esg-run-session"
    >
      {/* TODO: even though loading is true, children seem to try to render, thus erroring the UI. For example, try session!.name below */}
      <BlockUI loading={isLoading}>
        <FolderNavHeader
          crumbs={[
            { text: 'Sessions', href: APP_URI.SESSIONS },
            { text: session?.name || '', href: location.pathname },
          ]}
          title={session?.name || ''}
          description={session?.description}
          updateDescription={() => modalRef.current?.open()}
          icon="chart-bar-solid"
        />

        <Divider className="esg-run-session__divider" />

        <div className="esg-run-session__content">
          <GlowScroll variant="gray">
            <div
              className={classNames(
                'esg-run-session__content__container',
                'esg-run-session__content__container--left'
              )}
            >
              <div
                className="esg-run-session__content__header"
                aria-describedby="organisation-description"
              >
                Organisation
              </div>
              <div id="organisation-description">
                All the companies in your organisations are included in this
                session by default. Hover over a company to exclude it from the
                session.
              </div>

              {organisationTree && organisationId ? (
                <BasicTreeView
                  withMenu
                  data={{
                    ...organisationTree,
                  }}
                  showRootNode={false}
                  activeNodeId={organisationId}
                  className="esg-folders-layout__basic-tree"
                />
              ) : (
                <Loading />
              )}
            </div>
          </GlowScroll>

          <Divider orientation="vertical" />

          <GlowScroll variant="gray">
            <div
              className={classNames(
                'esg-run-session__content__container',
                'esg-run-session__content__container--right'
              )}
            >
              <div
                className="esg-run-session__content__header"
                aria-describedby="campaign-description"
              >
                Campaign:
                <div className="esg-run-session__content__header__button">
                  <Icon name="leaf" />
                  <span>{session?.campaignName}</span>
                </div>
              </div>
              <div id="campaign-description">
                All the topics and subtopics of the selected campaign are
                included in this session by default. You can exclude specific
                topics and subtopics for each company using the checkboxes
              </div>
              {campaignTree ? (
                <CheckboxTreeView
                  filteredData={campaignTree}
                  onCheck={() => {
                    setCampaignTree(cloneDeep(campaignTree));
                  }}
                />
              ) : (
                <div className="esg-company__campaign__no-data">
                  <EmptyFolder title="No attached campaigns." iconName="file" />
                </div>
              )}
            </div>
          </GlowScroll>
        </div>

        <Divider className="esg-run-session__divider" />

        <Form
          onSubmit={handleRunSession}
          ref={formRef}
          className="esg-run-session__form"
        >
          <div className="esg-run-session__dates">
            Dates
            <div className="esg-run-session__dates__container">
              <FormField
                name="startDate"
                component={CalendarField}
                validate={validations.startDate}
                placeholder="Set start date"
                disablePast
              />
              <FormField
                name="endDate"
                component={CalendarField}
                validate={validations.endDate}
                placeholder="Set end date"
                disablePast
              />
            </div>
          </div>
          <div className="esg-run-session__buttons">
            <Button
              type="submit"
              disabled={!form?.syncFormValid || !form?.isFormChanged()}
            >
              Start Session
            </Button>
            <Button variant="outline">Save draft</Button>
          </div>
        </Form>

        <EntityFormModal
          ref={modalRef}
          renderAsPortal
          className="esg-company-modal"
          onClose={() => modalRef.current?.close()}
          initialData={session}
          title={`Edit ${session?.name} session`}
          onSubmit={handleSubmit}
          fieldsConfiguration={{
            NAME: true,
            DESCRIPTION: true,
            EMAIL: false,
            ROLE: false,
            TYPE: false,
            CAMPAIGN: true,
          }}
        />
      </BlockUI>
    </StyledRunSession>
  );
};

export default RunSession;
