import { theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledFolderNavHeader = styled.header`
  .esg-folder-nav-header {
    &__breadcrumbs {
      a {
        ${fontSize(theme.fontSizes.FONT_14)};
        text-decoration: none;
      }
    }

    &__header {
      ${flex('row', 'flex-start', 'center')};

      gap: ${theme.sizes.SIZE_16};
      margin-top: ${theme.sizes.SIZE_12};

      .wcl-icon {
        > svg {
          ${size(theme.sizes.SIZE_40)};
        }
      }

      h2 {
        ${fontSize(theme.fontSizes.FONT_34)};
        font-weight: 400;
        margin: 0;
      }
    }

    &__description {
      margin-bottom: ${theme.sizes.SIZE_24};
      font-size: ${theme.fontSizes.FONT_14};
    }
  }

  .esg-folder-description {
    margin: ${theme.sizes.SIZE_12} 0;

    .button--ghost {
      min-height: ${theme.sizes.SIZE_48};
      padding: ${theme.sizes.SIZE_4} ${theme.sizes.SIZE_8};
      font-size: ${theme.fontSizes.FONT_14};
    }
  }
`;

export { StyledFolderNavHeader };
