// const apiVersion = env.VITE_API_VERSION;
const apiVersion = 'v1';
//mock because all other routes uses V2
export const USERS = {
  LOGIN: `/users/${apiVersion}/auth`,
  NEW_PASSWORD: `/users/${apiVersion}/password-challenge`,
  USER: `/users/${apiVersion}`,
  USER_ID: (id: string) => `/users/${apiVersion}/${id}`,
  LOGOUT: `/users/${apiVersion}/sign-out`,
  ROLES: `/users/${apiVersion}/roles`,
  REFRESH_TOKEN: `/users/${apiVersion}/refresh-token`,
  PROFILE: `/users/${apiVersion}/profile`,
};
