import { useUtilities } from '@faxi/web-component-library';
import api from 'api';
import { Loading } from 'components';
import { FC, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useMutation from '../../../../../../../api/hooks/mutations/useMutation';
import { API_ROUTES } from '../../../../../../../api/routes';
import { APP_URI } from '../../../../../../../config';
import { User } from '../../../../../../../models';
import { useRootProvider } from '../../../../../../../providers/Root';
import ProfileDetails from '../../../../../../components/ProfileDetails';
import UserProfileActions from '../../../../components/UserProfileActions';

const UserByIdDetails: FC = () => {
  const { userId = '' } = useParams();

  const { showSnackBar, hideOverlay, showOverlay } = useUtilities();

  const { data: user, isLoading, mutate: mutateUser } = api.useGetUser(userId);
  const {
    data: users,
    isLoading: isUsersLoading,
    mutate: refetchUsers,
  } = api.useGetUsers();

  const { userTokenInformation: currentUser } = useRootProvider();
  const navigate = useNavigate();

  const selectedUser = useMemo(
    () => users?.find((u) => u.id === user?.id),
    [user?.id, users]
  );

  const { trigger: deleteUser } = useMutation(
    API_ROUTES.USERS.USER_ID(userId),
    {
      onSuccess: () => {
        refetchUsers((cache) => {
          if (!cache) return;
          return {
            data: cache.data.filter((cacheUser) => cacheUser.id !== user?.id),
          };
        }, false);

        showSnackBar({
          text: `Successfully deleted ${selectedUser?.firstName} ${selectedUser?.lastName}.`,
          variant: 'success',
          actionButtonText: 'Dismiss',
        });
        navigate(APP_URI.USERS, { replace: true });
        hideOverlay('body');
      },
      onError: () => hideOverlay('body'),
      revalidate: false,
    }
  );

  const { isMutating: isMutatingUpdateUser, trigger: updateUser } =
    useMutation<{ data: User }>(API_ROUTES.USERS.USER_ID(userId), {
      onError: () => hideOverlay('body'),
      onSuccess: ({ data }) => {
        refetchUsers((cache) => {
          if (!cache) return;
          return {
            data: cache.data.map((user) =>
              user.id === data.id ? { ...user, ...data } : user
            ),
          };
        }, false);

        // TODO: check if mutation is needed
        mutateUser({ data });

        showSnackBar({
          text: `Successfully updated ${data.firstName} ${data.lastName}.`,
          variant: 'success',
          actionButtonText: 'Dismiss',
        });
        hideOverlay('body');
      },
      revalidate: false,
    });

  if (isLoading || isUsersLoading) {
    return <Loading />;
  }

  const managedUser = user && {
    ...user,
    roles: selectedUser?.roles ?? [],
  };

  return (
    <>
      <ProfileDetails user={managedUser} />
      <UserProfileActions
        user={managedUser}
        isLoading={isMutatingUpdateUser}
        onEdit={(data) => {
          showOverlay('body');
          updateUser({ method: 'PATCH', data });
        }}
        onDelete={() => {
          showOverlay('body');
          deleteUser({ method: 'DELETE' });
        }}
        isEditingOwnProfile={userId === currentUser?.id}
      />
    </>
  );
};

export default UserByIdDetails;
