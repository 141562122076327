import {
  Button,
  GlowScroll,
  Input,
  useModalUtilities,
} from '@faxi/web-component-library';
import classNames from 'classnames';
import { SessionCard } from 'components';
import Icon from 'components/Icon';
import { BlockUI } from 'helpers';
import { CampaignSessionStatus, Session } from 'models';
import { useSessionProvider } from 'providers/Session';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import SessionModal from './components/SessionModal';
import { StyledSessions } from './Sessions.styled';
import { mockProgressAndApproval } from './utils';

const Sessions: FC = () => {
  const { open, openModal, closeModal } = useModalUtilities();

  const { isLoading, sessions, search, error, setSearch } =
    useSessionProvider();

  const navigate = useNavigate();

  const onSelectCard = useCallback(
    (session: Session) => {
      // TODO
      // const isSessionActive = session.status === CampaignSessionStatus.Active;
      // const basePage = !isSessionActive ? 'SESSION_RUN' : 'SESSION_DASHBOARD';
      navigate(`${session.id}?organisationId=${session.rootOrganisationId}`);
    },
    [navigate]
  );

  return (
    <StyledSessions
      title="Sessions"
      direction="column"
      className="esg-sessions"
      padding={0}
    >
      <GlowScroll variant="gray">
        <div className="esg-sessions__container">
          <Button
            icon={<Icon name="plus" />}
            variant="outline"
            onClick={openModal}
          >
            Create a new session
          </Button>
          <div className="esg-sessions__container__filters">
            <Input
              className="esg-sessions__search-input"
              prefixIcon={<Icon name="magnifying-glass" />}
              placeholder="Search"
              {...(search && {
                suffixIcon: (
                  <Button
                    variant="ghost"
                    aria-label="Clear all"
                    icon={<Icon name="xmark" />}
                    onClick={() => setSearch('')}
                  />
                ),
              })}
              value={search}
              onChange={setSearch}
            />
          </div>
          {open && <SessionModal onClose={closeModal} />}
          <div
            className={classNames('esg-sessions__container__list', {
              'esg-sessions__container__list--no-data': !sessions.length,
            })}
          >
            <BlockUI
              loading={isLoading}
              fallbackCondition={!sessions.length}
              error={error}
            >
              {sessions.map((session) => {
                // TODO
                // const progress = ([] as SessionProgress[]).find(
                //   (progress) => progress.id === session.id
                // );

                // const sessionProgress = {
                //   currentStep: progress ? progress.completedElements : 0,
                //   numberOfSteps: progress ? progress.totalElements : 1,
                //   status:
                //     progress?.progress === 100
                //       ? FORM_STATUS.COMPLETED
                //       : FORM_STATUS.IN_PROGRESS,
                // };

                return (
                  <SessionCard
                    onSelect={() => onSelectCard(session)}
                    key={session.id}
                    session={{
                      ...session,
                      // progress: sessionProgress,
                      ...(session.status === CampaignSessionStatus.Active && {
                        approval: mockProgressAndApproval(false),
                      }),
                    }}
                  />
                );
              })}
            </BlockUI>
          </div>
        </div>
      </GlowScroll>
    </StyledSessions>
  );
};

export default Sessions;
