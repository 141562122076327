import { useUtilities } from '@faxi/web-component-library';
import api from 'api';
import { CampaignItem } from 'models';
import { useCallback } from 'react';
import { KeyedMutator } from 'swr';

import { API_ROUTES } from '../../../api/routes';
import { useCampaignProvider } from '../../../providers/Campaign';
import { useCampaignItem } from '../context/CampaignItem';

const useCampaignFormEntitySubmit = (
  campaignItemId: string,
  mutate: KeyedMutator<{ data: CampaignItem }>,
  currentCampaignItem?: CampaignItem
) => {
  const {
    trigger,
    data: campaignItemDataAPI,
    isMutating: createMutating,
  } = api.useMutation<{ data?: CampaignItem }>(
    API_ROUTES.CAMPAIGN_ITEMS_ROUTES.CAMPAIGN_ITEM(campaignItemId)
  );

  const campaignItemData = campaignItemDataAPI?.data;

  const {
    campaignItem,
    mutating: editMutating,
    editCampaignItem,
  } = useCampaignItem();

  const { mutateTree } = useCampaignProvider();

  const { showSnackBar } = useUtilities();

  const submitForm = useCallback(
    async (data: Record<string, any>) => {
      if (!currentCampaignItem) return;
      if (campaignItem?.id) {
        await editCampaignItem?.(data);

        if (campaignItem.id === campaignItemId) {
          mutate({
            data: {
              ...currentCampaignItem,
              name: data.name,
              description: data.description,
            },
          });

          return;
        }

        mutate({
          data: {
            ...currentCampaignItem,
            children: currentCampaignItem.children?.map((el) =>
              el.id === campaignItem.id
                ? { ...el, description: data?.description }
                : el
            ),
          },
        });
      } else {
        await trigger({
          method: 'POST',
          data: { ...data, type: data?.type ?? 'topic' },
        });

        mutate({
          data: {
            ...currentCampaignItem,
            ...(campaignItemData && {
              children: [campaignItemData],
            }),
          },
        });

        mutateTree();
      }
      showSnackBar({
        text: `${data?.name || campaignItem?.name} successfully ${campaignItem?.id ? 'updated' : 'created'}.`,
        variant: 'success',
        actionButtonText: 'Dismiss',
      });
    },
    [
      campaignItem,
      campaignItemData,
      campaignItemId,
      currentCampaignItem,
      editCampaignItem,
      mutate,
      mutateTree,
      trigger,
      showSnackBar,
    ]
  );

  return {
    submitForm,
    mutating: createMutating || editMutating,
  };
};

export default useCampaignFormEntitySubmit;
