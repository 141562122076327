import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledSessionHeader = styled.header`
  ${flex('row', 'flex-start', 'center')};
  gap: ${theme.sizes.SIZE_10};
  padding: ${theme.sizes.SIZE_24};
  ${fontSize(theme.fontSizes.FONT_14, theme.fontSizes.FONT_14)};

  .wcl-icon {
    ${fontSize(theme.fontSizes.FONT_30)};
  }
`;

export { StyledSessionHeader };
