import { DataModuleEnum, ModuleConfig, ModuleConfigType } from 'models';
import useSWR from 'swr';

import { API_ROUTES } from '../../routes';

// TODO:
// export all hooks from this

// TODO: delete
const useGetCampaignSessionForm = (sessionId?: string, formId?: string) => {
  const { data, ...rest } = useSWR(
    sessionId && formId
      ? API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.CAMPAIGN_SESSION_FORM(
          sessionId,
          formId
        )
      : ''
  );

  return {
    status: data?.status,
    approval: data?.approval,
    sessionFormId: data?.id,
    progress: data?.progress,
    elements: data?.elements as ModuleConfig<
      ModuleConfigType,
      DataModuleEnum
    >[],
    ...rest,
  };
};

export { useGetCampaignSessionForm };
