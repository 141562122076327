import api from 'api';
import { CampaignItem } from 'models';
import { FC, ReactNode, useCallback, useContext, useState } from 'react';
import { deleteProhibitedIds } from 'utils';

import { API_ROUTES } from '../../../../api/routes';
import CampaignItemContext, {
  CampaignItemContextProps,
} from './CampaignItem.context';

type CampaignItemProviderProps = {
  children?: ReactNode;
};

export type CampaignItemNameDesc = {
  name: string;
  description?: string;
  type?: string;
};

const CampaignItemProvider: FC<CampaignItemProviderProps> = (props) => {
  const { children } = props;

  const [campaignItem, setCampaignItem] = useState<CampaignItem>();

  const { trigger, isMutating: mutating } = api.useMutation(
    API_ROUTES.CAMPAIGN_ITEMS_ROUTES.CAMPAIGN_ITEM(campaignItem?.id as string)
  );

  const editCampaignItem = useCallback(
    async (data: Partial<CampaignItem>) => {
      if (data.name) {
        delete data.name;
        delete data.type;
      }

      deleteProhibitedIds(data);
      await trigger({ method: 'PATCH', data });
    },
    [trigger]
  );

  const removeActiveCampaignItem = useCallback(() => {
    setCampaignItem(undefined);
  }, []);

  return (
    <CampaignItemContext.Provider
      value={{
        mutating,
        campaignItem,
        setCampaignItem,
        removeActiveCampaignItem,
        editCampaignItem,
      }}
    >
      {children}
    </CampaignItemContext.Provider>
  );
};

const useCampaignItem = (): CampaignItemContextProps =>
  useContext(CampaignItemContext);

export { CampaignItemProvider, useCampaignItem };
