import { Campaign } from 'models';
import useSWR from 'swr';

import { API_ROUTES } from '../routes';

const useGetCampaigns = (onlyWithDataCollection = false) => {
  const { data, error, ...rest } = useSWR<{ data?: Campaign[] }>(
    `${API_ROUTES.CAMPAIGN_ITEMS_ROUTES.BASE}?onlyWithDataCollection=${onlyWithDataCollection}`
  );

  return {
    campaigns: data?.data,
    isError: error,
    ...rest,
  };
};

export default useGetCampaigns;
