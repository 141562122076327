/**
 * Splits a string into a specified number of parts based on a splitter.
 * If the number of resulting parts exceeds the specified number,
 * the last part will contain the remaining string after the specified number is reached.
 */

export const splitStringWithPartsLimit = (
  inputString: string,
  splitter: string,
  numOfParts: number
): string[] => {
  const splitParts = inputString.split(splitter);

  if (splitParts.length <= numOfParts) {
    return splitParts.map((part) => part.trim());
  } else {
    const initialParts = splitParts
      .slice(0, numOfParts - 1)
      .map((part) => part.trim());

    const remainingString = splitParts
      .slice(numOfParts - 1)
      .join(splitter)
      .trim();

    return [...initialParts, remainingString];
  }
};
