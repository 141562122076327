import { PropsWithChildren, ReactNode } from 'react';

import { StyledDataGridPreview } from './DataGridPreview.styled';

export type DataGridPreviewProps<T extends { id: string }> = PropsWithChildren<{
  data: Array<T>;
  renderColumns: (item: T) => ReactNode[];
}>;

const DataGridPreview = <T extends { id: string }>({
  data,
  renderColumns,
}: DataGridPreviewProps<T>) => {
  return (
    <StyledDataGridPreview className="esg-data-grid-preview">
      {data.map((item) => (
        <div
          key={item.id}
          className="esg-data-grid-preview__container"
          tabIndex={0}
        >
          {renderColumns(item).map((column, index) => (
            <div
              key={index}
              className="esg-data-grid-preview__container__column"
            >
              {column}
            </div>
          ))}
        </div>
      ))}
    </StyledDataGridPreview>
  );
};

export default DataGridPreview;
