import { APP_URI } from 'config';
import {
  RunSession,
  SessionDashboard,
  SessionDashboardCompany,
  SessionLayout,
  SessionReportsEntry,
  SessionReportsReport,
  Sessions,
} from 'pages';
import { RouteObject } from 'react-router-dom';

import SessionActivity from '../../../pages/Sessions/components/SessionActivity';
import SessionMembers from '../../../pages/Sessions/components/SessionMembers';

export default [
  { path: '', element: <Sessions /> },
  {
    path: APP_URI.SESSIONS_SESSION,
    element: <RunSession />,
  },
  // TODO: handle paths below
  {
    path: APP_URI.SESSION_DASHBOARD,
    element: <SessionLayout />,
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            element: <SessionDashboard />,
          },
          {
            path: APP_URI.SESSIONS_COMPANY,
            element: <SessionDashboardCompany />,
          },
        ],
      },
      {
        path: APP_URI.SESSION_ACTIVITY,
        element: <SessionActivity />,
      },
      {
        path: APP_URI.SESSION_MEMBERS,
        element: <SessionMembers />,
      },
      {
        path: APP_URI.SESSION_REPORT,
        children: [
          {
            path: '',
            element: <SessionReportsEntry name="organisation" />,
          },
          {
            path: APP_URI.SESSION_REPORT_COMPANY,
            element: <SessionReportsEntry name="company" />,
          },
          {
            path: APP_URI.SESSION_REPORT_COMPANY_TOPICS_TOPIC,
            element: <SessionReportsEntry name="topic" />,
          },
          {
            path: APP_URI.SESSION_REPORT_COMPANY_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC,
            element: <SessionReportsEntry name="subtopic" />,
          },
          {
            path: APP_URI.SESSION_REPORT_COMPANY_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC_SUBSUBTOPICS_SUBSUBTOPIC,
            element: <SessionReportsReport />,
          },
        ],
      },
    ],
  },
] as RouteObject[];
