import {
  ModalProps,
  ModalRef,
  SelectOption,
} from '@faxi/web-component-library';
import { FC, useCallback, useMemo, useRef } from 'react';

import useGetCampaigns from '../../../../api/hooks/useGetCampaigns';
import { EntityFormModal } from '../../../../components';
import { useCreateSession } from '../../hooks';

const SessionModal: FC<Pick<ModalProps, 'onClose'>> = ({ onClose }) => {
  const { campaigns, isLoading: loadingCampaigns } = useGetCampaigns(true);

  const modalRef = useRef<ModalRef>(null);

  const { createSession, isMutating: isCreateSessionMutating } =
    useCreateSession({
      onSuccess: () => {
        modalRef?.current?.close();
      },
    });

  const campaignsOptions: SelectOption<string>[] = useMemo(
    () =>
      campaigns?.map(({ id, name }) => ({
        value: id,
        label: name,
      })) ?? [],
    [campaigns]
  );

  const onSubmit = useCallback(
    async (data: Record<string, string>) => {
      const { campaign, name, description } = data;

      createSession({ name, description, campaignId: campaign });
    },
    [createSession]
  );

  return (
    <EntityFormModal
      ref={modalRef}
      renderAsPortal
      title="New session"
      conditionallyControlled
      onClose={onClose}
      onSubmit={onSubmit}
      loading={isCreateSessionMutating}
      fieldProps={{
        campaign: {
          options: campaignsOptions,
          loading: loadingCampaigns,
        },
      }}
      fieldsConfiguration={{
        NAME: true,
        DESCRIPTION: true,
        EMAIL: false,
        ROLE: false,
        TYPE: false,
        CAMPAIGN: true,
      }}
    />
  );
};

export default SessionModal;
