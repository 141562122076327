import { Button, Image } from '@faxi/web-component-library';
import { Form, FormField } from '@faxi/web-form';
import { AxiosError } from 'axios';
import { AuthResponse } from 'models';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import useMutation from '../../api/hooks/mutations/useMutation';
import { API_ROUTES } from '../../api/routes';
import { FormFooter } from '../../components';
import PasswordField from '../../components/_fields/PasswordField';
import { useCallbackAsync, useValidations } from '../../hooks';
import authBus, { AUTH_BUS_EVENTS } from '../../modules/authBus';
import { useRootProvider } from '../../providers/Root';
import { StyledAuth } from '../../routes/subrouters/Auth/Auth.styled';
import authService from '../../services/authService';
import credentialsService from '../../services/credentialsService';

export type NewPasswordForm = {
  password: string;
  repeatPassword: string;
};

const CreateNewPassword: FC = () => {
  const { validations } = useValidations();
  const { logoutUser } = useRootProvider();

  const navigate = useNavigate();

  const { trigger } = useMutation<AuthResponse, AxiosError<{ error: string }>>(
    API_ROUTES.USERS.NEW_PASSWORD,
    {
      onSuccess: ({ data }) => {
        authService.logoutNewUser();

        if (!authService.isNewUser(data)) {
          authService.loginUser(data);
          authBus.broadcastEvent(AUTH_BUS_EVENTS.UPDATE_USER, data.idToken);
        }

        navigate('/');
      },
    }
  );

  const [handleSubmit] = useCallbackAsync({
    showSpinner: true,
    callback: async (values: NewPasswordForm) => {
      const userIdForSrp = credentialsService.newUserToken;
      const session = credentialsService.newUserSession;

      const { password } = values;

      await trigger({
        method: 'POST',
        data: {
          userIdForSrp,
          session,
          newPassword: password,
        },
      });
    },
  });

  return (
    <StyledAuth className="esg-auth">
      <div className="app-name">
        <Image src="/assets/svg/toyota_dark.svg" alt="" />
        <h1>ESG Navigator</h1>
      </div>
      <Form onSubmit={handleSubmit} className="">
        <FormField
          name="password"
          component={PasswordField}
          placeholder="Password"
          validate={validations.password}
        />
        <FormField
          name="repeatPassword"
          placeholder="Repeat password"
          component={PasswordField}
          validate={validations.repeatPassword}
        />
        <FormFooter submitLabel="Confirm" />
        <Button className="logout-button" variant="ghost" onClick={logoutUser}>
          Logout?
        </Button>
      </Form>
    </StyledAuth>
  );
};

export default CreateNewPassword;
