import { SelectOption } from '@faxi/web-component-library';
import { RunSessionData, Session, TreeNodeElement } from 'models';
import { createContext } from 'react';

export type SessionContextProps = {
  sessions: Session[];
  search: string;
  selectedOrganisation?: SelectOption;
  organisationTree?: TreeNodeElement;
  isLoading: boolean;
  isRunMutating: boolean;
  error: boolean;

  removeSession: (sessionName: string) => void;
  runSession: (sessionName: string, data: RunSessionData) => void;
  setSearch: (value: string) => void;
  setSelectedOrganisation: (selected: SelectOption) => void;
};

export default createContext<SessionContextProps>({
  sessions: [],
  search: '',
  selectedOrganisation: { label: '', value: '' },
  isLoading: false,
  isRunMutating: false,
  error: false,

  removeSession: () => {},
  runSession: () => {},
  setSearch: () => {},
  setSelectedOrganisation: () => {},
});
