import { useUtilities } from '@faxi/web-component-library';
import {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import useMutation from '../../api/hooks/mutations/useMutation';
import useGetUserProfile from '../../api/hooks/users/useGetUserProfile';
import { API_ROUTES } from '../../api/routes';
import { APP_URI } from '../../config';
import authBus, { AUTH_BUS_EVENTS } from '../../modules/authBus';
import authService from '../../services/authService';
import credentialsService from '../../services/credentialsService';
import storageService from '../../services/storageService';
import { getErrorMessage, getUserInformationFromToken } from '../../utils';
import RootContext, { RootContextProps } from './Root.context';

type RootProviderProps = {
  children?: ReactNode;
};

const RootProvider: FC<RootProviderProps> = (props) => {
  const { children } = props;
  const navigate = useNavigate();

  const [userTokenInformation, setUserTokenInformation] =
    useState<RootContextProps['userTokenInformation']>();

  const { showSnackBar, showOverlay, hideOverlay } = useUtilities();

  const { trigger: logout } = useMutation<void>(API_ROUTES.USERS.LOGOUT);

  const { data: user, isLoading, error } = useGetUserProfile();

  const updateUserInformation = useCallback((token?: string) => {
    const info = getUserInformationFromToken(token);

    setUserTokenInformation(info);
  }, []);

  const logoutUser = useCallback(async () => {
    if (credentialsService.newUserToken) {
      authService.logoutNewUser();
      navigate(APP_URI.AUTH_LOGIN);
      return;
    }

    if (!credentialsService.token) return;

    try {
      showOverlay('body');
      await logout({ method: 'DELETE' });
    } catch (e) {
      showSnackBar({
        text: getErrorMessage(e),
        variant: 'error',
        actionButtonText: 'Dismiss',
      });
    } finally {
      //TODO: no refresh token logic, request will fail if token expiered
      //for now we logout user even if logout api call fail

      localStorage.clear();
      updateUserInformation();
      hideOverlay('body');
      navigate(APP_URI.AUTH_LOGIN);
    }
  }, [
    hideOverlay,
    logout,
    navigate,
    showOverlay,
    showSnackBar,
    updateUserInformation,
  ]);

  useEffect(() => {
    const userIdToken = storageService.getItem<string>('ID_TOKEN');
    updateUserInformation(userIdToken);

    const unsubscribeUpdateUser = authBus.addEventListener<string>(
      AUTH_BUS_EVENTS.UPDATE_USER,
      updateUserInformation
    );

    return () => {
      unsubscribeUpdateUser();
    };
  }, [updateUserInformation]);

  useEffect(() => {
    const unsubscribeLogout = authBus.addEventListener<void>(
      AUTH_BUS_EVENTS.LOGOUT,
      logoutUser
    );

    return () => {
      unsubscribeLogout();
    };
  }, [logoutUser]);

  return (
    // <BlockUI loading={isLoading} error={!!error}>
    <RootContext.Provider
      value={{
        // force existence because of blockUI
        user: user!,
        userTokenInformation,
        loadingUser: isLoading,
        errorUser: !!error,
        logoutUser,
      }}
    >
      {children}
    </RootContext.Provider>
    // </BlockUI>
  );
};

const useRootProvider = (): RootContextProps => useContext(RootContext);

export { RootProvider, useRootProvider };
