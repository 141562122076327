import classNames from 'classnames';
import { FC, memo } from 'react';

import { FormulaModuleConfig } from '../../../models';
import * as Styled from './FormulaElement.styled';

export type FormulaElementProps = {
  formula?: FormulaModuleConfig['formula'];
  noFormulaMessage?: string;
  error?: boolean;
  className?: string;
};

const FormulaElement: FC<FormulaElementProps> = ({
  formula,
  className,
  error,
  noFormulaMessage = 'No formula configured',
}) => (
  <Styled.StyledFormulaElement
    className={classNames(
      'esg-formula-element',
      { 'esg-formula-element--error': error },
      className
    )}
  >
    {formula?.map((tag) => tag.value).join(' ') ?? noFormulaMessage}
  </Styled.StyledFormulaElement>
);

export default memo(FormulaElement);
