import { Session, SessionProgress } from 'models';
import { useSessionProvider } from 'providers/Session';
import React, { useContext, useMemo } from 'react';

import {
  convertProgressApiToProgress,
  mockProgressAndApproval,
} from '../../utils';
import SelectedSessionContext from './SelectedSession.context';

type SelectedSessionProviderProps = {
  sessionId: string;
  children: React.ReactNode;
};

const SelectedSessionProvider: React.FC<SelectedSessionProviderProps> = ({
  sessionId,
  children,
}) => {
  const { sessions } = useSessionProvider();

  const selectedSession = useMemo(() => {
    // TODO
    const selectedSessionProgress = ([] as SessionProgress[]).find(
      (progress) => progress?.id === sessionId
    );

    const session = sessions.find((session) => session.id === sessionId);
    const convertedProgress = convertProgressApiToProgress(
      selectedSessionProgress
    );
    return {
      ...session,
      progress: convertedProgress,
      approval: mockProgressAndApproval(),
    };
  }, [sessions, sessionId]);

  if (!selectedSession) return <></>;

  return (
    <SelectedSessionContext.Provider value={selectedSession as Session}>
      {children}
    </SelectedSessionContext.Provider>
  );
};
const useSelectedSession = () => useContext(SelectedSessionContext);

export { SelectedSessionProvider, useSelectedSession };
