import { StatusElementStatus } from '@faxi/web-component-library';
import Icon, { INameExtended } from 'components/Icon';
import {
  DataModuleEnum,
  ModuleConfig,
  ModuleConfigType,
  SectionType,
} from 'models';

import { campaignPathCheckpoints } from '../../utils';

export const dataModuleTextMapper: Record<DataModuleEnum, string> = {
  'section': 'Section',
  'text-box': 'Text box',
  // 'data-graph': 'Data / Graph',
  'switch': 'Switch',
  'upload': 'Upload',
  'input': 'Input',
  'choice': 'Choice',
  'formula': 'Formula',
  'integration': 'Integration',
  'checklist': 'Checklist',
  'divider': 'Divider',
  'dropdown': 'Dropdown',
};

export const mappedSectionTypeToStatus: Record<
  SectionType,
  StatusElementStatus
> = {
  'data-collection': 'active',
  report: 'pending',
  folder: 'draft',
};

export const mappedSectionTypeToIcon: Record<SectionType, INameExtended> = {
  'data-collection': 'magnifying-glass-chart',
  report: 'chart-simple',
  folder: 'folder',
};

export const mappedSectionTypeToTitle: Record<SectionType, string> = {
  'data-collection': 'Data Collection',
  report: 'Report',
  'folder': 'Folder',
};

export const mapTypeToIcon: Record<DataModuleEnum, INameExtended> = {
  'section': 'rectangle',
  'text-box': 'text',
  // 'data-graph': 'chart-simple',
  'switch': 'toggle-on',
  'upload': 'upload',
  'input': 'input-pipe',
  'choice': 'ballot-check',
  'formula': 'function',
  'divider': 'horizontal-rule',
  'checklist': 'square-check',
  'integration': 'gears',
  'dropdown': 'caret-down',
};

export const mapTypeToTitle: Partial<Record<DataModuleEnum, string>> = {
  'text-box': 'Text configuration',
  // 'data-graph': 'Data graph configuration',
  'switch': 'Switch configuration',
  'upload': 'Upload document configuration',
  'input': 'Input Field configuration ',
  'choice': 'Choice configuration',
  'checklist': 'Checklist configuration',
  'dropdown': 'Dropdown configuration',
  'integration': 'Integration configuration',
};

export const campaignItemTypeSelectOptions = [
  {
    value: 'data_collection_element',
    label: 'Data collection',
    icon: <Icon color="#0066D5" name="magnifying-glass-chart" />,
  },
  {
    value: 'topic',
    label: 'Folder',
    icon: <Icon name="folder" />,
  },
];

export const generateCampaignItemCrumbs = (
  names: string[] = [],
  ids: string[] = [],
  id: string,
  name: string
) => {
  let path = '';

  const namesTemp = [...names, name];
  const idsTemp = [...ids, id];

  const rootCrumb = {
    text: 'Campaigns',
    href: `/${campaignPathCheckpoints[0]}`,
    id: 'campaigns-link',
  };

  return [
    rootCrumb,
    ...namesTemp.map((name, index) => {
      path = `${path}/${campaignPathCheckpoints[index]}/${idsTemp[index]}`;

      return {
        text: name,
        href: path,
        id: idsTemp[index],
      };
    }),
  ];
};

const prepareElements = (
  elements: ModuleConfig<ModuleConfigType, DataModuleEnum>[]
): { elements: ModuleConfig<ModuleConfigType, DataModuleEnum>[] } => ({
  elements: elements.map((el, index) => ({
    index,
    type: el.type,
    title: el.title,
    ...((!Object.values(DataModuleEnum).includes(el.id as DataModuleEnum) && {
      id: el.id.split('+')[0],
      // TODO: we should return id
    }) as { id: string }),
    ...(el.conditionalElements && {
      conditionalElements: el.conditionalElements,
    }),
    ...(el.config && { config: el.config }),
    ...(el.type === 'section' && prepareElements(el.elements || [])),
  })),
});

export const prepareCampaignFormObject = (
  modules: ModuleConfig<ModuleConfigType, DataModuleEnum>[]
) => ({
  form: prepareElements(modules),
});
