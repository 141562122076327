import useSWR from 'swr';

import { Session } from '../../../models';
import { API_ROUTES } from '../../routes';

const useGetSession = (sessionId: string) => {
  const { data, ...rest } = useSWR<{
    data: Session;
  }>(API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.SESSION(sessionId));
  return {
    session: data?.data,
    ...rest,
  };
};

export default useGetSession;
