import { Activity } from '../../../../models';

const activities: Activity[] = [
  {
    id: '1',
    isRead: false,
    user: {
      firstName: 'Andrija',
      lastName: 'Cvetkovic',
      img: '/assets/images/avatars/kumca.png',
    },
    campaignName: 'ESG Report 2024',
    organisation: {
      name: 'Kinto Join Ltd',
    },
    topic: 'Environment',
    action: 'Completed Report',
  },
  {
    id: '2',
    isRead: true,
    user: {
      firstName: 'Nemanja',
      lastName: 'Stojanovic',
      img: '/assets/images/avatars/nemca.jpeg',
    },
    campaignName: 'ESG Report 2024',
    organisation: {
      name: 'Kinto Join Ltd',
    },
    topic: 'Environment',
    action: 'Left message',
  },
  {
    id: '3',
    isRead: true,
    user: {
      firstName: 'Andrea',
      lastName: 'Popovic',
    },
    campaignName: 'ESG Report 2024',
    organisation: {
      name: 'Kinto Join Ltd',
    },
    topic: 'Environment',
    action: 'Completed Report',
  },
];

export const sessionActivities = [
  {
    date: new Date(),
    activities,
  },
];
