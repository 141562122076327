import {
  Button,
  GlowScroll,
  Tabs,
  useModalUtilities,
  useUtilities,
} from '@faxi/web-component-library';
import { API_ROUTES } from 'api/routes';
import Icon from 'components/Icon';
import { APP_URI } from 'config';
import { User } from 'models';
import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import api from '../../api';
import { BlockUI } from '../../helpers';
import { useLayoutPreviewMode } from '../../hooks';
import { previewTabs } from '../../hooks/useLayoutPreviewMode';
import { LayoutPreview } from '../../models/LayoutPreview';
import ManageUserModal from './components/ManageUserModal';
import UsersPreview from './components/UsersPreview.component';
import { StyledUsers } from './Users.styled';

export type NewSessionForm = {
  name: string;
  description: string;
};

export type UserForm = {
  firstName: string;
  lastName: string;
  email: string;
  roleId: string;
  jobTitle?: string;
};

const Users: FC = () => {
  const [selectedUser, setSelectedUser] = useState<
    Omit<UserForm, 'email'> & { id: string }
  >();

  const navigate = useNavigate();
  const { open, openModal, closeModal } = useModalUtilities();
  const { prompts, showOverlay, hideOverlay, showSnackBar } = useUtilities();
  const {
    data: users,
    isLoading,
    mutate: refetchUsers,
    error,
  } = api.useGetUsers();

  const { view, setView } = useLayoutPreviewMode('grid');

  const { isMutating: isMutatingCreateUser, trigger: triggerCreateUser } =
    api.useMutation<{ data: User }>(API_ROUTES.USERS.USER, {
      onSuccess: ({ data }) => {
        showSnackBar({
          text: `Successfully created ${data.firstName} ${data.lastName}.`,
          variant: 'success',
          actionButtonText: 'Dismiss',
        });

        const container = document.getElementsByClassName(
          'esg-users__container'
        )[0];
        container.scrollTo({
          top: container.scrollHeight,
          behavior: 'smooth',
        });
        closeModal();
      },
    });

  const { isMutating: isMutatingUpdateUser, trigger: triggerUpdateUser } =
    api.useMutation<{ data: User }>(API_ROUTES.USERS.USER, {
      onSuccess: ({ data }) => {
        refetchUsers((cache) => {
          if (!cache) return;
          return {
            data: cache.data.map((user) =>
              user.id === data.id ? { ...user, ...data } : user
            ),
          };
        }, false);

        showSnackBar({
          text: `Successfully updated ${data.firstName} ${data.lastName}.`,
          variant: 'success',
          actionButtonText: 'Dismiss',
        });
        setSelectedUser(undefined);
        closeModal();
      },
    });

  const { trigger: triggerDeleteUser } = api.useMutation(
    API_ROUTES.USERS.USER,
    {
      onSuccess: () => {
        refetchUsers((cache) => {
          if (!cache) return;
          return {
            data: cache.data.filter((user) => user.id !== selectedUser?.id),
          };
        }, false);

        showSnackBar({
          text: `Successfully deleted ${selectedUser?.firstName} ${selectedUser?.lastName}.`,
          variant: 'success',
          actionButtonText: 'Dismiss',
        });
        setSelectedUser(undefined);
        hideOverlay('.esg-users');
      },
      onError: () => hideOverlay('.esg-users'),
    }
  );

  const onSubmit = useCallback(
    async (data: UserForm) => {
      try {
        showOverlay('.esg-users');

        if (selectedUser)
          triggerUpdateUser({
            url: API_ROUTES.USERS.USER_ID(selectedUser.id),
            method: 'PATCH',
            data,
          });
        else
          triggerCreateUser({
            url: API_ROUTES.USERS.USER,
            method: 'POST',
            data,
          });
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('.esg-users');
      }
    },
    [
      hideOverlay,
      selectedUser,
      showOverlay,
      triggerCreateUser,
      triggerUpdateUser,
    ]
  );

  return (
    <BlockUI loading={isLoading} error={!!error}>
      <StyledUsers
        title="Users"
        direction="column"
        className="esg-users"
        padding={0}
      >
        <GlowScroll variant="gray">
          <div className="esg-users__container">
            <div className="esg-users__container__header">
              <div className="esg-users__container__header__buttons">
                <Button
                  icon={<Icon name="plus" />}
                  variant="outline"
                  onClick={openModal}
                >
                  Create a new user
                </Button>

                <Button
                  icon={<Icon name="user-gear" />}
                  variant="outline"
                  onClick={() => navigate(APP_URI.USER_ROLES)}
                >
                  User roles
                </Button>
              </div>
              {users?.length! > 1 && (
                <Tabs<LayoutPreview>
                  value={view}
                  className="esg-users-tabs"
                  labelPosition="right"
                  tabs={previewTabs}
                  onChange={setView}
                  changeOrientationOnResponsive={false}
                />
              )}
            </div>
            {open && (
              <ManageUserModal
                initialData={selectedUser}
                onClose={() => {
                  setSelectedUser(undefined);
                  closeModal();
                }}
                loading={isMutatingCreateUser || isMutatingUpdateUser}
                onSubmit={onSubmit}
              />
            )}

            <div className="esg-users__container__cards">
              <UsersPreview
                users={users}
                view={view}
                onEdit={({ email, ...user }) => {
                  setSelectedUser({ ...user, roleId: user.roles?.[0]?.id });
                  openModal();
                }}
                onClick={(user) => navigate(user.id)}
                onDelete={async (user, e) =>
                  await prompts.delete({
                    onClose: () => setSelectedUser(undefined),
                    type: 'delete',
                    submitBtnText: 'Delete',
                    cancelBtnText: 'Do not delete',
                    title: `Delete ${user.firstName} ${user.lastName}`,
                    content: `Are you sure you want to delete ${user.firstName} ${user.lastName} from users?`,
                    btnIcon: 'trash-can',
                    iconPosition: 'left',
                    submitBtnVariant: 'delete-ghost',
                    titleIcon: <Icon name="triangle-exclamation" />,
                    triggerRef: e.target as HTMLButtonElement,
                    onSubmit: async () => {
                      showOverlay('.esg-users');
                      triggerDeleteUser({
                        url: API_ROUTES.USERS.USER_ID(user.id),
                        method: 'DELETE',
                      });
                    },
                  })
                }
              />
            </div>
          </div>
        </GlowScroll>
      </StyledUsers>
    </BlockUI>
  );
};

export default Users;
