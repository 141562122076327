import {
  Sidebar as WCLSidebar,
  SidebarItem,
} from '@faxi/web-component-library';
import classNames from 'classnames';
import { AnimatedLogo } from 'components/_atoms';
import { INameExtended } from 'components/Icon';
import { APP_URI } from 'config';
import { PermissionSections } from 'models';
import { FC, useMemo } from 'react';

import { useRootProvider } from '../../providers/Root';
import { hasRouteAllPermissions } from '../../utils';

const SIDEBAR_ARIA_LABELS = {
  ariaDeleteLabel: 'Delete Label',
  ariaCloseMenu: 'Collapse sidebar',
  ariaOpenMenu: 'Expand sidebar',
  ariaExpand: 'Expand sidebar',
  ariaCollapse: 'Collapse sidebar',
  ariaLogoLabel: 'ESG Navigator logo',
};

const DEFAULT_SIDEBAR_PROPS = {
  searchPlaceholder: '',
  noResultsLabel: '',
  logoutText: 'Log out',
  topItemsTitle: '',
  AnimatedLogoComponent: AnimatedLogo,
  initialExpanded: false,
};

const SIDEBAR_MAIN_ITEMS: (SidebarItem<INameExtended> & {
  permissionGroup?: PermissionSections[];
})[] = [
  {
    id: '1',
    name: 'Sessions',
    url: APP_URI.SESSIONS,
    iconName: 'chart-bar',
    permissionGroup: [PermissionSections.SESSION],
  },
  {
    id: '2',
    name: 'Organisations',
    url: APP_URI.ORGANISATIONS,
    iconName: 'buildings',
    permissionGroup: [PermissionSections.ORGANISATION],
  },
  {
    id: '3',
    name: 'Campaigns',
    url: APP_URI.CAMPAIGNS,
    iconName: 'leaf',
    permissionGroup: [PermissionSections.CAMPAIGN],
  },
  {
    id: '4',
    name: 'Users',
    url: APP_URI.USERS,
    iconName: 'users',
    permissionGroup: [PermissionSections.USER],
  },
];

const SIDEBAR_BOTTOM_ITEMS: SidebarItem<INameExtended>[] = [
  {
    name: 'Account settings',
    url: APP_URI.ACCOUNT_SETTINGS,
    id: 'account-settings',
  },
  {
    name: 'Personal Details',
    url: APP_URI.PERSONAL_DETAILS,
    id: 'personal-details',
  },
  {
    name: 'Notifications',
    url: APP_URI.NOTIFICATIONS,
    id: 'notifications',
  },
  {
    name: 'Assignments',
    url: APP_URI.ASSIGNMENTS,
    id: 'assignments',
  },
];

export type SidebarProps = { className?: string };

const Sidebar: FC<SidebarProps> = ({ className }) => {
  const {
    logoutUser,
    userTokenInformation: {
      firstName = '',
      lastName = '',
      userRouterPermissions,
    } = {},
  } = useRootProvider();

  const finalSidebarRoutes = useMemo<any[]>(() => {
    return [
      {
        items: SIDEBAR_MAIN_ITEMS.filter(
          ({ permissionGroup }) =>
            !permissionGroup ||
            (userRouterPermissions &&
              hasRouteAllPermissions(userRouterPermissions, permissionGroup))
        ),
      },
    ];
  }, [userRouterPermissions]);

  return (
    <WCLSidebar
      className={classNames('esg-sidebar', className)}
      logoUrl="/assets/svg/logo.svg"
      logoAlt="ESG Navigator logo"
      ariaLabels={SIDEBAR_ARIA_LABELS}
      mainItems={finalSidebarRoutes}
      bottomItems={SIDEBAR_BOTTOM_ITEMS}
      logoTo={APP_URI.BASE}
      user={{
        name: `${firstName} ${lastName}`,
        image_url: '/assets/images/avatar.png',
      }}
      onClickLogout={logoutUser}
      {...DEFAULT_SIDEBAR_PROPS}
    />
  );
};

export default Sidebar;
