import { INameExtended } from '../components/Icon';
import { ObjectValues } from '../types';
import { BaseModelExtended } from './BaseModel';
import { CampaignItem } from './Campaign';
import { Company, CompanyChild } from './Company';

export type SessionAPI = {
  name: 'string';
  companyId: 'string';
  campaignId: 'string';
  selectedElementsIds: ['string'];
};

export const FORM_STATUS = {
  COMPLETED: 'Completed',
  IN_PROGRESS: 'In Progress',
  IN_REVIEW: 'In Review',
} as const;

export type FormStatus = ObjectValues<typeof FORM_STATUS>;

export const FORM_APPROVAL = {
  TO_REVIEW: 'To Review',
  APPROVED: 'Approved',
} as const;

export type FormApproval = ObjectValues<typeof FORM_APPROVAL>;
export type ApprovalStatus = FormStatus | FormApproval;

export type ProgressData = {
  currentStep: number;
  numberOfSteps: number;
  status: ApprovalStatus;
};

export type SessionProgress = {
  completedElements: number;
  id: string;
  progress: number;
  sessionDescription: string;
  sessionName: string;
  totalElements: number;
  companyName: string;
};

export type SessionSettings = {
  organisationId: string;
  organisationPath: string;
  isIncluded: boolean;
  dataCollectionElements: {
    id: string;
    path: string;
    isIncluded: boolean;
    exclusionJustification?: string;
  }[];
};

export type Session = BaseModelExtended & {
  id: string;
  status: CampaignSessionStatus;
  campaignId: string;
  campaignName: string;
  settings: SessionSettings[];
  startDate?: string;
  endDate?: string;
  createdBy: string;
  updatedBy?: string;
  rootOrganisationId: string;
};

export enum CampaignSessionStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export type CampaignSessionTreeNode = {
  id: string;
  organisationSessionId: string;
  name: string;
  companySessionId?: string;
  status: CampaignSessionStatus;
  description: string;
  path: {
    ids: string[];
    names: string[];
  };
  organisation: Company;
  campaign?: CampaignItem;
  parent?: {
    id: string;
    name: string;
  };
  iconName?: INameExtended;
  children?: CampaignSessionTreeNode[];
};

export type SessionsPageParams = { sessionId: string };

export type SessionTree = BaseModelExtended & {
  campaign: CampaignItem;
  children: SessionTree[];
  company: CompanyChild;
  status: CampaignSessionStatus;
  organisationSessionId: string;
};

export type SessionDashboardData = {
  icon: string;
  name: string;
  description?: string;
  items: {
    icon: string;
    name: string;
    status: ApprovalStatus;
    currentStep: number;
    numberOfSteps: number;
  }[];
};

export type RunSessionData = {
  companyIds: Array<string>;
};
