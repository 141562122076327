import { FormulaDataModule } from 'models';
import { FC, memo } from 'react';

import { FormulaElement } from '../../../../../_molecules';
import * as Styled from './FormulaPreview.styled';

export type FormulaPreviewProps = FormulaDataModule;

const FormulaPreview: FC<FormulaPreviewProps> = (props) => {
  const { config } = props;

  return (
    <Styled.StyledFormulaPreview className="esg-formula-preview">
      <div className="esg-formula-preview__label">Formula:</div>

      <div className="esg-formula-preview__formula">
        <FormulaElement formula={config?.formula} error={!config?.formula} />
      </div>
    </Styled.StyledFormulaPreview>
  );
};

export default memo(FormulaPreview);
