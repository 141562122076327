import { applyRef, ModalProps, ModalRef } from '@faxi/web-component-library';
import { Form, FormField, FormProps, FormRef } from '@faxi/web-form';
import { InputField, SelectField, TextareaField } from 'components/_fields';
import { InputFieldProps } from 'components/_fields/InputField/InputField.component';
import { SelectFieldProps } from 'components/_fields/SelectField/SelectField.component';
import { TextareaFieldProps } from 'components/_fields/TextareaField/TextareaField.component';
import {
  forwardRef,
  ForwardRefRenderFunction,
  Fragment,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  Ref,
  RefObject,
  useCallback,
  useMemo,
  useRef,
} from 'react';

import { useValidations } from '../../../hooks';
import Icon from '../../Icon';
import FormFooter from '../FormFooter';
import { StyledEntityFormModal } from './EntityFormModal.styled';

export type EntityFormFieldProps = {
  fieldProps?: Record<
    string,
    InputFieldProps & TextareaFieldProps & SelectFieldProps
  >;
};

// order of these dictates the order in UI
enum FIELD_NAMES {
  TYPE,
  NAME,
  CAMPAIGN,
  EMAIL,
  DESCRIPTION,
  ROLE,
}

type IFormFieldMapper = (
  initialData:
    | {
        [key: string]: any;
      }
    | undefined,
  fieldProps: EntityFormFieldProps['fieldProps'],
  validations: Record<string, any>
) => Record<keyof typeof FIELD_NAMES, ReactNode>;

const formFieldMapper: IFormFieldMapper = (
  initialData,
  fieldProps,
  validations
) => ({
  TYPE: (
    <FormField
      key="type"
      name="type"
      component={SelectField}
      placeholder="Type"
      options={[]}
      validate={!initialData ? validations.type : []}
      disabled={!!initialData}
      {...fieldProps?.['type']}
    />
  ),
  ROLE: (
    <FormField
      key="role"
      name="role"
      component={SelectField}
      placeholder="Role"
      options={[]}
      validate={validations.role}
      {...fieldProps?.['role']}
    />
  ),
  NAME: (
    <FormField
      key="name"
      name="name"
      component={InputField}
      autoComplete="off"
      placeholder="Name"
      validate={validations.name}
      disabled={!!initialData}
      {...fieldProps?.['name']}
    />
  ),
  EMAIL: (
    <FormField
      key="email"
      name="email"
      component={InputField}
      autoComplete="off"
      placeholder="Email"
      validate={validations.email}
      {...fieldProps?.['email']}
    />
  ),
  DESCRIPTION: (
    <FormField
      key="description"
      name="description"
      component={TextareaField}
      max={150}
      placeholder="Description"
      validate={validations.description}
      {...fieldProps?.['description']}
    />
  ),
  CAMPAIGN: (
    <Fragment key="campaign">
      <FormField
        name="campaign"
        component={SelectField}
        options={[]}
        placeholder="Choose campaign"
        disabled={!!initialData}
        validate={validations.campaign}
        {...fieldProps?.['campaign']}
        aria-describedby="campaign-description"
      />
      <div id="campaign-description" className="campaign-description">
        <Icon name="circle-info" />
        <div>
          The selected campaign will be attached to this session and will be
          propagated to companies and subcompanies.
        </div>
      </div>
    </Fragment>
  ),
});

export type EntityFormModalProps<T extends Record<string, any>> =
  PropsWithChildren<
    ModalProps &
      Omit<FormProps<T>, 'onSubmit'> & {
        onSubmit: (
          data: T,
          e?: React.FormEvent<Element>
        ) => Promise<FormRef | void>;
        initialData?: {
          [key: string]: any;
        };
        fieldsConfiguration: Record<keyof typeof FIELD_NAMES, boolean>;
      } & EntityFormFieldProps
  >;

const EntityFormModal: ForwardRefRenderFunction<
  ModalRef,
  EntityFormModalProps<Record<string, any>>
> = <T extends Record<string, any>>(
  props: EntityFormModalProps<T>,
  ref: Ref<ModalRef>
) => {
  const {
    children,
    initialData,
    onSubmit,
    fieldProps,
    fieldsConfiguration = {
      TYPE: false,
      NAME: true,
      DESCRIPTION: true,
      EMAIL: false,
      ROLE: false,
      CAMPAIGN: false,
    },
    loading,
    ...rest
  } = props;

  const { validations } = useValidations();

  // TODO: this is just temporary :)
  const initialDataUsed = useMemo(
    () =>
      initialData
        ? {
            name: initialData.name,
            description: initialData.description,
            ...(fieldsConfiguration.TYPE && { type: initialData.type }),
            ...(fieldsConfiguration.EMAIL && { email: initialData.email }),
            ...(fieldsConfiguration.ROLE && { role: initialData.role }),
            ...(fieldsConfiguration.DESCRIPTION && {
              role: initialData.description,
            }),
          }
        : undefined,
    [fieldsConfiguration, initialData]
  );

  const modalRef = useRef<ModalRef>();

  const formWrapper = useCallback(
    ({ children, className }: PropsWithChildren<{ className: string }>) => (
      <Form
        children={children}
        className={className}
        initialData={initialDataUsed}
        onSubmit={onSubmit}
      />
    ),
    [initialDataUsed, onSubmit]
  );

  return (
    <StyledEntityFormModal
      ref={(el: ModalRef) => {
        applyRef(ref, el);
        modalRef.current = el;
      }}
      loading={loading}
      closeOnEscape
      childrenWrapper={formWrapper}
      className="esg-entity-form-modal"
      conditionallyControlled={false}
      footer={<FormFooter modal={modalRef as RefObject<ModalRef>} />}
      {...rest}
    >
      {children}

      {(Object.values(FIELD_NAMES) as (keyof typeof FIELD_NAMES)[]).map(
        (name) => {
          const component = formFieldMapper(
            initialData,
            fieldProps,
            validations
          )[name];

          return fieldsConfiguration[name] ? component : null;
        }
      )}
    </StyledEntityFormModal>
  );
};

export default forwardRef(EntityFormModal) as <T extends Record<string, any>>(
  props: EntityFormModalProps<T> & { ref?: React.Ref<ModalRef> }
) => ReactElement;
