import { SelectOption, Tag } from '@faxi/web-component-library';
import { FormField } from '@faxi/web-form';
import { FormulaDataModule } from 'models';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import api from '../../../../../api';
import TagsInputField from '../../../../../components/_fields/TagsInputField';
import { StyledBaseFormulaFieldConfiguration } from './BaseFormulaFieldConfiguration.styled';

export type BaseFormulaFieldConfigurationProps = FormulaDataModule;
const BaseFormulaFieldConfiguration: FC<BaseFormulaFieldConfigurationProps> = (
  props
) => {
  const { campaign = '' } = useParams<{
    campaign: string;
  }>();

  const { dataCollectionElements } =
    api.useGetCampaignDataCollectionElements(campaign);

  const tagsOptions = useMemo<SelectOption[]>(
    () =>
      dataCollectionElements?.map(({ title, id }) => ({
        label: title,
        value: id,
      })) || [],
    [dataCollectionElements]
  );

  return (
    <StyledBaseFormulaFieldConfiguration className="esg-text-field-configuration">
      <FormField
        component={TagsInputField}
        placeholder="Type here"
        withOperators
        toolboxIcon="calculator-simple"
        name="formula"
        tagsOptions={tagsOptions}
        validate={[
          (tags: Tag[]) => {
            if (!tags.length) return 'This field is required';

            if (tags.some((t) => t.error)) return 'Error';

            return '';
          },
        ]}
      />
    </StyledBaseFormulaFieldConfiguration>
  );
};

export default BaseFormulaFieldConfiguration;
