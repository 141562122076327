import { FormulaDataModule } from 'models';
import { FC, memo } from 'react';

import { FormulaElement } from '../../../../_molecules';
import { StyledModuleContainer } from '../../styles/ModuleContainer.styled';

export type FormulaCanvasModuleProps = FormulaDataModule;

const FormulaCanvasModule: FC<FormulaCanvasModuleProps> = (props) => {
  const { config } = props;

  return (
    <StyledModuleContainer className="esg-formula-canvas-module">
      <FormulaElement formula={config?.formula} noFormulaMessage="Formula" />
    </StyledModuleContainer>
  );
};

export default memo(FormulaCanvasModule);
