export const ORGANISATION_PATH_CHECKPOINTS = ['organisations', 'companies'];
export const SESSION_PATH_CHECKPOINTS = ['sessions', 'organisation'];

export const getPathSegment = (path: string, from = 0, to = 2) => {
  const segments = path.split('/').filter(Boolean);
  return `/${segments.slice(from, to).join('/')}`;
};

export const generateOrganisationItemCrumbs = (
  names = [] as string[],
  ids = [] as string[],
  id: string,
  name: string
) => {
  const namesTemp = [...names, name];
  const idsTemp = [...ids, id];

  let path = '';

  return [
    ...namesTemp.map((name, index) => {
      path = `${idsTemp[index]}/run`;

      return {
        text: name,
        href: index === 0 ? '/organisations' : path,
        id: idsTemp[index],
      };
    }),
  ];
};
