import { applyRef, ModalProps, ModalRef } from '@faxi/web-component-library';
import { Form, FormField } from '@faxi/web-form';
import { FormFooter } from 'components';
import PasswordField from 'components/_fields/PasswordField';
import { useValidations } from 'hooks';
import {
  forwardRef,
  ForwardRefRenderFunction,
  PropsWithChildren,
  RefObject,
  useCallback,
  useRef,
} from 'react';

import { NewPasswordForm } from '../../../../../pages/CreateNewPassword/CreateNewPassword.page';
import { StyledChangePasswordModal } from './ChangePasswordModal.styled';

export type ChangePasswordModalProps = PropsWithChildren<
  ModalProps & {
    onSubmit: (data: NewPasswordForm) => Promise<void>;
  }
>;

const ChangePasswordModal: ForwardRefRenderFunction<
  ModalRef,
  ChangePasswordModalProps
> = (props, ref) => {
  const { onSubmit: pOnSubmit, ...rest } = props;

  const modalRef = useRef<ModalRef>();

  const { validations } = useValidations();

  const onSubmit = useCallback(
    async (data: NewPasswordForm) => {
      await pOnSubmit(data);
    },
    [pOnSubmit]
  );

  const formWrapper = useCallback(
    ({ children, className }: PropsWithChildren<{ className: string }>) => (
      <Form children={children} className={className} onSubmit={onSubmit} />
    ),
    [onSubmit]
  );

  return (
    <StyledChangePasswordModal
      className="esg-manage-user-modal"
      ref={(el: ModalRef) => {
        applyRef(ref, el);
        modalRef.current = el;
      }}
      childrenWrapper={formWrapper}
      title="Change Password"
      footer={
        <FormFooter
          modal={modalRef as RefObject<ModalRef>}
          submitLabel="Save"
        />
      }
      {...rest}
    >
      <p>Change password</p>
      <FormField
        name="password"
        component={PasswordField}
        placeholder="Password"
        validate={validations.password}
      />
      <FormField
        name="repeatPassword"
        placeholder="Repeat password"
        component={PasswordField}
        validate={validations.repeatPassword}
      />
    </StyledChangePasswordModal>
  );
};

export default forwardRef(ChangePasswordModal);
