import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledNoData = styled.div`
  ${flex('column', 'center', 'center')};
  height: 100%;

  gap: ${theme.sizes.SIZE_8};

  .wcl-icon {
    ${fontSize(theme.fontSizes.FONT_24)};
  }
`;

export { StyledNoData };
