import { FC, PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';

import { BlockUI } from '../../helpers';
import { RootProvider, useRootProvider } from '../../providers/Root';
import Sidebar from '../Sidebar';
import { StyledRoot } from './Root.styled';

const RootContent: FC<PropsWithChildren> = ({ children }) => {
  const { loadingUser, errorUser } = useRootProvider();

  return (
    <BlockUI loading={loadingUser} error={errorUser}>
      <StyledRoot>
        <Sidebar />
        <Outlet />
      </StyledRoot>
    </BlockUI>
  );
};

const Root: FC = () => (
  <RootProvider>
    <RootContent />
  </RootProvider>
);

export default Root;
